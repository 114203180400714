import React, { useState, useCallback, useEffect, useRef } from 'react';
import ChatWindow from './components/ChatWindow';
import KeywordSearch from './components/KeywordSearch';
import ImageGenerator from './components/ImageGenerator';
import ImageHistory from './components/ImageHistory';
import Upscaler from './components/Upscaler';
import ProjectManager from './components/ProjectManager';
import { UpscalerRef } from './types/upscaler';
import { ImageGeneratorRef } from './types/imageGenerator';
import { Project } from './types/project';
import { fal } from "@fal-ai/client";
import { 
  updateHistory, 
  updateCustomInstructions, 
  updateMessageContext, 
  updateConnections,
  getAgents 
} from './services/api';
import './App.css';

const WS_URL = process.env.NODE_ENV === 'development' 
  ? 'ws://localhost:3000/ws'
  : 'wss://aitoolhub.mimo.ooo/ws';

type View = 'agents' | 'imageGenerator' | 'upscaler' | 'imageHistory';


interface ImageHistoryProps {
  onSelectForUpscale: (imagePath: string) => void;
  onSelectForImageToImage: (imagePath: string) => void;
}

const App: React.FC = () => {
  const [currentView, setCurrentView] = useState<View>('agents');
  const [agents, setAgents] = useState<string[]>([]);
  const [generatedImageUrl, setGeneratedImageUrl] = useState<string | null>(null);
  const [apiKey, setApiKey] = useState<string>('');
  const [isApiConnected, setIsApiConnected] = useState<boolean>(false);
  const [freepikApiKey, setFreepikApiKey] = useState<string>('');
  const [isFreepikConnected, setIsFreepikConnected] = useState<boolean>(false);
  
  // Nowe stany dla obsługi projektów
  const [isProjectManagerOpen, setIsProjectManagerOpen] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState<string>('');
  const [connections, setConnections] = useState<{ [key: string]: string[] }>({});

  const imageGeneratorRef = useRef<ImageGeneratorRef>(null);
  const upscalerRef = useRef<UpscalerRef>(null);
  const [lastGeneratedPrompt, setLastGeneratedPrompt] = useState<string | null>(null);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const handleProjectLoad = async (project: Project) => {
    try {
      // Resetuj aktualny stan
      setGeneratedImageUrl(null);
      setError(null);
      setIsLoading(true);

      // Załaduj agentów
      const agentPromises = project.agents.map(async (agent) => {
        // Zapisz historię
        await updateHistory(agent.name, agent.history);
        
        // Zapisz instrukcje
        await updateCustomInstructions(agent.name, agent.instructions);
        
        // Zapisz konteksty
        await updateMessageContext(agent.name, 'context1', agent.contexts.pre);
        await updateMessageContext(agent.name, 'context2', agent.contexts.post);
        
        return agent.name;
      });

      // Poczekaj na załadowanie wszystkich agentów
      const loadedAgents = await Promise.all(agentPromises);
      setAgents(loadedAgents);
      
      // Ustaw pierwszego agenta jako wybranego
      if (loadedAgents.length > 0) {
        setSelectedAgent(loadedAgents[0]);
      }

      // Załaduj połączenia między agentami
      await updateConnections(project.connections);
      setConnections(project.connections);

      // Załaduj obrazy
      if (project.images.generated.length > 0) {
        setGeneratedImageUrl(project.images.generated[project.images.generated.length - 1]);
      }

      // Odśwież widok agentów
      await fetchAgents();

      // Zamknij okno managera projektów
      setIsProjectManagerOpen(false);

      // Pokaż powiadomienie o sukcesie
      alert(`Project "${project.name}" loaded successfully!`);

    } catch (error) {
      console.error('Error loading project:', error);
      setError(error instanceof Error ? error.message : 'Failed to load project');
    } finally {
      setIsLoading(false);
    }
  };

  const onAgentsUpdate = useCallback((updatedAgents: string[]) => {
    setAgents(updatedAgents);
  }, []);

  const fetchAgents = useCallback(async () => {
    try {
      const fetchedAgents = await getAgents();
      setAgents(fetchedAgents);
      if (fetchedAgents.length > 0 && !selectedAgent) {
        setSelectedAgent(fetchedAgents[0]);
      }
      onAgentsUpdate(fetchedAgents);
    } catch (error) {
      console.error('Error fetching agents:', error);
      setError('Failed to fetch agents');
    }
  }, [selectedAgent]);
  

  useEffect(() => {
    const ws = new WebSocket(WS_URL);
    
    ws.onopen = () => {
      console.log('WebSocket Connected');
    };
    
    ws.onerror = (error) => {
      console.error('WebSocket Error:', error);
    };
    
    ws.onclose = () => {
      console.log('WebSocket Disconnected');
    };
    
    return () => {
      ws.close();
    };
  }, []);

  useEffect(() => {
    const savedApiKey = localStorage.getItem('falApiKey');
    if (savedApiKey) {
      setApiKey(savedApiKey);
      connectToApi(savedApiKey);
    }

    const savedFreepikApiKey = localStorage.getItem('freepikApiKey');
    if (savedFreepikApiKey) {
      setFreepikApiKey(savedFreepikApiKey);
      connectToFreepikApi(savedFreepikApiKey);
    }
  }, []);

  const connectToApi = async (key: string) => {
    try {
      fal.config({
        credentials: key
      });
      setIsApiConnected(true);
      localStorage.setItem('falApiKey', key);
    } catch (error) {
      console.error('Failed to connect to fal.ai API:', error);
      setIsApiConnected(false);
    }
  };

  const connectToFreepikApi = async (key: string) => {
    try {
      localStorage.setItem('freepikApiKey', key);
      setIsFreepikConnected(true);
    } catch (error) {
      console.error('Failed to connect to Freepik API:', error);
      setIsFreepikConnected(false);
    }
  };

  const handleApiKeyChange = (newKey: string) => {
    setApiKey(newKey);
    connectToApi(newKey);
  };

  const handleFreepikApiKeyChange = (newKey: string) => {
    setFreepikApiKey(newKey);
    connectToFreepikApi(newKey);
  };

  const handleAgentsUpdate = useCallback((updatedAgents: string[]) => {
    setAgents(updatedAgents);
  }, []);

  const handleImageGenerated = useCallback((imageUrl: string) => {
    setGeneratedImageUrl(imageUrl);
    setCurrentView('imageGenerator');
  }, []);

  const handleSelectForUpscale = (imagePath: string) => {
    setSelectedImage(imagePath);
    setCurrentView('upscaler');
    if (upscalerRef.current) {
      upscalerRef.current.loadImage(imagePath);
    }
  };

  const handleSelectForImageToImage = (imagePath: string) => {
    setSelectedImage(imagePath);
    setCurrentView('imageGenerator');
    if (imageGeneratorRef.current) {
      // Dodajemy nową metodę do referencji
      imageGeneratorRef.current.setImageToImage(imagePath);
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <div className="logo-container">
          <img src="/logo/logo.png" alt="MIMO" className="logo" />
          <h1 className="app-title">Content AI Creator.</h1>
        </div>
        <div className="header-controls">
          <button 
            className="project-manager-button"
            onClick={() => setIsProjectManagerOpen(true)}
          >
            Manage Projects
          </button>
        </div>
        <nav className="App-nav">
          <button 
            className={`nav-button ${currentView === 'agents' ? 'active' : ''}`}
            onClick={() => setCurrentView('agents')}
          >
            AI Agents
          </button>
          <button 
            className={`nav-button ${currentView === 'imageGenerator' ? 'active' : ''}`}
            onClick={() => setCurrentView('imageGenerator')}
          >
            Image Generator
          </button>
          <button 
            className={`nav-button ${currentView === 'upscaler' ? 'active' : ''}`}
            onClick={() => setCurrentView('upscaler')}
          >
            Upscaler
          </button>
          <button 
            className={`nav-button ${currentView === 'imageHistory' ? 'active' : ''}`}
            onClick={() => setCurrentView('imageHistory')}
          >
            Image History
          </button>
        </nav>
      </header>
      {isProjectManagerOpen && (
        <ProjectManager
          onProjectLoad={handleProjectLoad}
          onClose={() => setIsProjectManagerOpen(false)}
        />
      )}
      <main className="App-main">
        <div className={`view-container ${currentView === 'agents' ? 'active' : ''}`}>
          <div className="chat-container">
            <ChatWindow 
              onAgentsUpdate={handleAgentsUpdate}
              onImageGenerated={handleImageGenerated}
              imageGeneratorRef={imageGeneratorRef}
              onNewPrompt={setLastGeneratedPrompt}
            />
          </div>
          <div className="search-container">
            <h2>Keyword Search</h2>
            <KeywordSearch />
          </div>
        </div>
        <div className={`view-container ${currentView === 'imageGenerator' ? 'active' : ''}`}>
          <ImageGenerator 
            ref={imageGeneratorRef}
            agents={agents} 
            initialImageUrl={generatedImageUrl}
            apiKey={apiKey}
            isApiConnected={isApiConnected}
            onApiKeyChange={handleApiKeyChange}
            lastGeneratedPrompt={lastGeneratedPrompt}
            selectedImage={selectedImage}
          />
        </div>
        <div className={`view-container ${currentView === 'upscaler' ? 'active' : ''}`}>
          <Upscaler 
            ref={upscalerRef}
            apiKey={freepikApiKey}
            isApiConnected={isFreepikConnected}
            onApiKeyChange={handleFreepikApiKeyChange}
            selectedImage={selectedImage}
          />
        </div>
        <div className={`view-container ${currentView === 'imageHistory' ? 'active' : ''}`}>
          <ImageHistory
            onSelectForUpscale={handleSelectForUpscale}
            onSelectForImageToImage={handleSelectForImageToImage}
          />
        </div>
      </main>
      <footer className="App-footer">
        <p>© 2024 MIMO Content AI Creator. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default App;