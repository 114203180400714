import React from 'react';

interface AgentSumQuestionsProps {
  tofuQuestions: string[];
  mofuQuestions: string[];
}

const AgentSumQuestions: React.FC<AgentSumQuestionsProps> = ({ tofuQuestions, mofuQuestions }) => {
  return (
    <div className="agent-sum-questions">
      <div className="questions-section tofu-questions">
        <h3>Pytania TOFU (podstawowe)</h3>
        <ul>
          {tofuQuestions.map((question, index) => (
            <li key={`tofu-${index}`}>{question}</li>
          ))}
        </ul>
      </div>
      <div className="questions-section mofu-questions">
        <h3>Pytania MOFU (zaawansowane)</h3>
        <ul>
          {mofuQuestions.map((question, index) => (
            <li key={`mofu-${index}`}>{question}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default AgentSumQuestions;