import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { UpscaleOptions, UpscaleTask, UpscalerProps, UpscalerRef } from '../types/upscaler';
import '../styles/Upscaler.css';

interface PresetOptions extends Omit<UpscaleOptions, 'image'> {}

const defaultOptions: UpscaleOptions = {
  image: '',
  scale_factor: '2x',
  optimized_for: 'standard',
  engine: 'automatic',
  creativity: 0,
  hdr: 0,
  resemblance: 0,
  fractality: 0
};

const Upscaler = forwardRef<UpscalerRef, UpscalerProps>(({
  apiKey: externalApiKey,
  isApiConnected: externalIsApiConnected,
  onApiKeyChange,
  selectedImage
}, ref) => {
  const TASK_CHECK_INTERVAL = 30000; // 30 sekund

  const [options, setOptions] = useState<UpscaleOptions>(defaultOptions);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [preview, setPreview] = useState<string>('');
  const [activeTasks, setActiveTasks] = useState<{[key: string]: NodeJS.Timeout}>({});
  const [completedImages, setCompletedImages] = useState<string[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [presets, setPresets] = useState<{ [key: string]: PresetOptions }>({});
  const [presetName, setPresetName] = useState('');

  useImperativeHandle(ref, () => ({
    loadImage: async (imagePath: string) => {
      try {
        const response = await fetch(imagePath);
        const blob = await response.blob();
        const file = new File([blob], 'selected-image.png', { type: 'image/png' });
        setSelectedFile(file);
        setPreview(URL.createObjectURL(file));

        // Od razu konwertujemy do base64
        const base64 = await fileToBase64(file);
        setOptions(prev => ({
          ...prev,
          image: base64
        }));
      } catch (error) {
        console.error('Error loading image:', error);
        setError('Failed to load selected image');
      }
    }
  }), []);

  useEffect(() => {
    if (selectedImage && ref) {
      (ref as any).current?.loadImage(selectedImage);
    }
  }, [selectedImage, ref]);

  useEffect(() => {
    // Wczytaj ostatnie ustawienia
    const savedOptions = localStorage.getItem('upscalerOptions');
    if (savedOptions) {
      const parsedOptions = JSON.parse(savedOptions);
      setOptions(prev => ({
        ...prev,
        ...parsedOptions,
        image: '' // Zawsze resetuj image
      }));
    }

    // Wczytaj zapisane presety
    const savedPresets = localStorage.getItem('upscalerPresets');
    if (savedPresets) {
      setPresets(JSON.parse(savedPresets));
    }
  }, []);

  useEffect(() => {
    return () => {
      Object.values(activeTasks).forEach(intervalId => {
        console.log('Cleaning up interval:', intervalId);
        clearInterval(intervalId);
      });
    };
  }, [activeTasks]);

  const fileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          // Usuwamy prefix "data:image/xxx;base64," z wyniku
          const base64 = reader.result.split(',')[1];
          resolve(base64);
        } else {
          reject(new Error('Failed to convert file to Base64'));
        }
      };
      reader.onerror = (error) => reject(error);
    });
  };

  const savePreset = () => {
    if (!presetName.trim()) {
      setError('Please enter a preset name');
      return;
    }

    const presetOptions: PresetOptions = {
      scale_factor: options.scale_factor,
      optimized_for: options.optimized_for,
      prompt: options.prompt,
      creativity: options.creativity,
      hdr: options.hdr,
      resemblance: options.resemblance,
      fractality: options.fractality,
      engine: options.engine
    };

    const newPresets = {
      ...presets,
      [presetName]: presetOptions
    };

    setPresets(newPresets);
    localStorage.setItem('upscalerPresets', JSON.stringify(newPresets));
    setPresetName('');
  };

  const loadPreset = (name: string) => {
    const preset = presets[name];
    if (preset) {
      setOptions(prev => ({
        ...prev,
        ...preset
      }));
    }
  };

  const deletePreset = (name: string) => {
    const newPresets = { ...presets };
    delete newPresets[name];
    setPresets(newPresets);
    localStorage.setItem('upscalerPresets', JSON.stringify(newPresets));
  };

  const handleApiKeySubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const response = await fetch('http://localhost:5000/api/upscale/test-connection', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': externalApiKey
        }
      });

      if (response.ok) {
        onApiKeyChange(externalApiKey);
      } else {
        setError('Failed to connect to Freepik API');
      }
    } catch (error) {
      setError('Error testing API connection');
    }
  };

  const handleFileSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      try {
        setSelectedFile(file);
        setPreview(URL.createObjectURL(file));
        
        const base64 = await fileToBase64(file);
        setOptions(prev => ({
          ...prev,
          image: base64
        }));
      } catch (error) {
        console.error('Error processing file:', error);
        setError('Failed to process selected file');
      }
    }
  };

  const saveImageOnServer = async (url: string, filename: string) => {
    try {
      const response = await fetch('http://localhost:5000/api/save-upscaled-image', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ url, filename }),
      });

      if (!response.ok) {
        throw new Error('Failed to save image on server');
      }

      const result = await response.json();
      console.log(`Image saved at: ${result.path}`);
    } catch (error) {
      console.error('Error saving image on server:', error);
      setError('Failed to save image on server');
    }
  };

  const checkTaskStatus = async (taskId: string) => {
    try {
      console.log('Checking task status for task:', taskId);
      const response = await fetch(`http://localhost:5000/api/upscale/task/${taskId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': externalApiKey
        }
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const responseData = await response.json();
      console.log('Task status response:', responseData);

      // Dostęp do właściwości przez data
      const taskData = responseData.data;

      if (taskData.status === 'COMPLETED') {
        console.log('Task completed, generated images:', taskData.generated);
        
        if (taskData.generated && taskData.generated.length > 0) {
          // Zapisujemy wygenerowane obrazy
          for (const imageUrl of taskData.generated) {
            const filename = `upscaled-${taskId}-${Date.now()}.png`;
            try {
              await saveImageOnServer(imageUrl, filename);
              console.log(`Saved image: ${filename}`);
            } catch (error) {
              console.error(`Failed to save image ${filename}:`, error);
            }
          }

          // Aktualizujemy stan z nowymi obrazami
          setCompletedImages(prev => [...prev, ...taskData.generated]);
          
          // Zatrzymujemy sprawdzanie
          if (activeTasks[taskId]) {
            clearInterval(activeTasks[taskId]);
            setActiveTasks(prev => {
              const newTasks = {...prev};
              delete newTasks[taskId];
              return newTasks;
            });
          }
          setIsLoading(false);
          return true;
        }
      } else if (taskData.status === 'FAILED') {
        console.error('Task failed');
        setError('Task failed');
        if (activeTasks[taskId]) {
          clearInterval(activeTasks[taskId]);
          setActiveTasks(prev => {
            const newTasks = {...prev};
            delete newTasks[taskId];
            return newTasks;
          });
        }
        setIsLoading(false);
        return true;
      }

      // Task wciąż w trakcie przetwarzania
      console.log('Task still in progress');
      return false;

    } catch (error) {
      console.error('Error checking task status:', error);
      setError('Error checking task status');
      if (activeTasks[taskId]) {
        clearInterval(activeTasks[taskId]);
        setActiveTasks(prev => {
          const newTasks = {...prev};
          delete newTasks[taskId];
          return newTasks;
        });
      }
      setIsLoading(false);
      return true;
    }
  };

  const handleUpscale = async () => {
    if (!selectedFile) {
      setError('Please select an image first');
      return;
    }

    try {
      setIsLoading(true);
      setError(null);

      // Sprawdzamy, czy mamy obraz w options
      if (!options.image) {
        const base64Image = await fileToBase64(selectedFile);
        setOptions(prev => ({
          ...prev,
          image: base64Image
        }));
      }

      console.log('Sending upscale request with options:', {
        ...options,
        image: options.image ? '[BASE64_STRING]' : 'MISSING!'
      });

      const response = await fetch('http://localhost:5000/api/upscale/image', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': externalApiKey
        },
        body: JSON.stringify({ options })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
      }

      const responseData = await response.json();
      console.log('Initial upscale response:', responseData);

      if (responseData.data && responseData.data.task_id) {
        const taskId = responseData.data.task_id;
        console.log(`Starting status check for task ${taskId}`);

        // Ustawiamy interwał sprawdzania co 30 sekund
        const intervalId = setInterval(async () => {
          try {
            const isFinished = await checkTaskStatus(taskId);
            if (isFinished) {
              clearInterval(intervalId);
              setActiveTasks(prev => {
                const newTasks = {...prev};
                delete newTasks[taskId];
                return newTasks;
              });
            }
          } catch (error) {
            console.error('Error in status check interval:', error);
            clearInterval(intervalId);
            setError('Error checking task status');
            setIsLoading(false);
          }
        }, 30000);

        setActiveTasks(prev => ({
          ...prev,
          [taskId]: intervalId
        }));

        // Pierwsze sprawdzenie od razu
        await checkTaskStatus(taskId);
      } else {
        throw new Error('No task ID received from server');
      }

    } catch (error) {
      console.error('Error in handleUpscale:', error);
      setError(error instanceof Error ? error.message : 'Error during upscale process');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="upscaler">
      <div className="api-connection">
        <form onSubmit={handleApiKeySubmit}>
          <div className="input-group">
            <label htmlFor="apiKey">Freepik API Key:</label>
            <input
              type="password"
              id="freepikApiKey"
              value={externalApiKey}
              onChange={(e) => onApiKeyChange(e.target.value)}
              placeholder="Enter your Freepik API key"
            />
            <button 
              type="submit"
              className={`connect-button ${externalIsApiConnected ? 'connected' : ''}`}
              disabled={externalIsApiConnected}
            >
              {externalIsApiConnected ? 'Connected' : 'Connect to API'}
            </button>
          </div>
        </form>
      </div>

      <div className="upscaler-controls">
        <h2>Image Upscaler</h2>

        <div className="presets-manager">
          <div className="save-preset">
            <input
              type="text"
              value={presetName}
              onChange={(e) => setPresetName(e.target.value)}
              placeholder="Preset name"
            />
            <button onClick={savePreset}>Save Preset</button>
          </div>
          
          <div className="preset-list">
            {Object.entries(presets).map(([name, preset]) => (
              <div key={name} className="preset-item">
                <span>{name}</span>
                <div className="preset-actions">
                  <button onClick={() => loadPreset(name)}>Load</button>
                  <button onClick={() => deletePreset(name)} className="delete-preset">×</button>
                </div>
              </div>
            ))}
          </div>
        </div>
        
        <div className="file-upload">
          <input
            type="file"
            accept="image/jpeg,image/png,image/webp"
            onChange={handleFileSelect}
            disabled={isLoading}
          />
          {preview && (
            <div className="preview">
              <img src={preview} alt="Preview" />
            </div>
          )}
        </div>

        <div className="options">
          <div className="option-group">
            <label>Scale Factor:</label>
            <select
              value={options.scale_factor}
              onChange={(e) => setOptions(prev => ({
                ...prev,
                scale_factor: e.target.value as UpscaleOptions['scale_factor']
              }))}
            >
              <option value="2x">2x</option>
              <option value="4x">4x</option>
              <option value="8x">8x</option>
              <option value="16x">16x</option>
            </select>
          </div>

          <div className="option-group">
            <label>Optimized For:</label>
            <select
              value={options.optimized_for}
              onChange={(e) => setOptions(prev => ({
                ...prev,
                optimized_for: e.target.value as UpscaleOptions['optimized_for']
              }))}
            >
              <option value="standard">Standard</option>
              <option value="soft_portraits">Soft Portraits</option>
              <option value="hard_portraits">Hard Portraits</option>
              <option value="art_n_illustration">Art & Illustration</option>
              <option value="videogame_assets">Videogame Assets</option>
              <option value="nature_n_landscapes">Nature & Landscapes</option>
              <option value="films_n_photography">Films & Photography</option>
              <option value="3d_renders">3D Renders</option>
              <option value="science_fiction_n_horror">Sci-fi & Horror</option>
            </select>
          </div>

          <div className="option-group">
            <label>Engine:</label>
            <select
              value={options.engine}
              onChange={(e) => setOptions(prev => ({
                ...prev,
                engine: e.target.value as UpscaleOptions['engine']
              }))}
            >
              <option value="automatic">Automatic</option>
              <option value="magnific_illusio">Magnific Illusio</option>
              <option value="magnific_sharpy">Magnific Sharpy</option>
              <option value="magnific_sparkle">Magnific Sparkle</option>
            </select>
          </div>

          <div className="option-group">
            <label>Prompt:</label>
            <textarea
              value={options.prompt || ''}
              onChange={(e) => setOptions(prev => ({
                ...prev,
                prompt: e.target.value
              }))}
              placeholder="Optional: Guide the upscale process..."
            />
          </div>

          {['creativity', 'hdr', 'resemblance', 'fractality'].map((param) => (
            <div key={param} className="slider-group">
              <label>{param.charAt(0).toUpperCase() + param.slice(1)}:</label>
              <input
                type="range"
                min="-10"
                max="10"
                value={options[param as keyof UpscaleOptions] || 0}
                onChange={(e) => setOptions(prev => ({
                  ...prev,
                  [param]: parseInt(e.target.value)
                }))}
              />
              <span>{options[param as keyof UpscaleOptions] || 0}</span>
            </div>
          ))}
        </div>

        <button
          onClick={handleUpscale}
          disabled={!selectedFile || !externalIsApiConnected}
          className="upscale-button"
        >
          {isLoading ? 'Processing...' : 'Upscale Image'}
        </button>

        {error && <div className="error-message">{error}</div>}
      </div>

      {Object.keys(activeTasks).length > 0 && isLoading && (
        <div className="active-tasks">
          <h3>Processing Tasks</h3>
          <p>Currently processing {Object.keys(activeTasks).length} task(s)...</p>
        </div>
      )}

      {completedImages.length > 0 && (
        <div className="completed-images">
          <h3>Completed Images</h3>
          <div className="results-grid">
            {completedImages.map((url, index) => (
              <div key={index} className="result-image">
                <img src={url} alt={`Upscaled ${index + 1}`} />
                <a 
                  href={url} 
                  download={`upscaled-${index + 1}.png`}
                  className="download-button"
                  onClick={(e) => {
                    e.preventDefault();
                    fetch(url)
                      .then(response => response.blob())
                      .then(blob => {
                        const downloadUrl = window.URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = downloadUrl;
                        link.download = `upscaled-${index + 1}.png`;
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        window.URL.revokeObjectURL(downloadUrl);
                      });
                  }}
                >
                  Download
                </a>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
});

export default Upscaler;