import React, { useState, useEffect } from 'react';
import { getAgentHistoryConfig, updateAgentHistoryConfig, deleteAgent, renameAgent } from '../services/api';

interface AgentSelectorProps {
  agents: string[];
  selectedAgent: string;
  onSelectAgent: (agent: string) => void;
  onResetHistory: (agent: string) => void;
  onAddAgent: (agentName: string) => void;
  onAgentsUpdate: () => void;
}

const AgentSelector: React.FC<AgentSelectorProps> = ({
  agents,
  selectedAgent,
  onSelectAgent,
  onResetHistory,
  onAddAgent,
  onAgentsUpdate
}) => {
  const [newAgentName, setNewAgentName] = useState('');
  const [agentHistoryConfigs, setAgentHistoryConfigs] = useState<{[key: string]: string[]}>({});
  const [editingAgent, setEditingAgent] = useState<string | null>(null);
  const [editName, setEditName] = useState('');

  useEffect(() => {
    const loadAllHistoryConfigs = async () => {
      const configs: {[key: string]: string[]} = {};
      for (const agent of agents) {
        try {
          const selectedHistories = await getAgentHistoryConfig(agent);
          configs[agent] = selectedHistories;
        } catch (error) {
          console.error(`Error loading history config for ${agent}:`, error);
          configs[agent] = [];
        }
      }
      setAgentHistoryConfigs(configs);
    };

    loadAllHistoryConfigs();
  }, [agents]);

  const handleHistorySelection = async (targetAgent: string, sourceAgent: string, isSelected: boolean) => {
    const newConfig = {
      ...agentHistoryConfigs,
      [targetAgent]: isSelected
        ? [...(agentHistoryConfigs[targetAgent] || []), sourceAgent]
        : (agentHistoryConfigs[targetAgent] || []).filter(a => a !== sourceAgent)
    };
    
    setAgentHistoryConfigs(newConfig);

    try {
      await updateAgentHistoryConfig(targetAgent, newConfig[targetAgent]);
    } catch (error) {
      console.error('Error updating history config:', error);
    }
  };

  const handleAddAgent = () => {
    if (newAgentName.trim() && !agents.includes(newAgentName.trim())) {
      onAddAgent(newAgentName.trim());
      setNewAgentName('');
    }
  };

  const handleDeleteAgent = async (agent: string) => {
    if (window.confirm(`Are you sure you want to delete agent ${agent}?`)) {
      try {
        await deleteAgent(agent);
        onAgentsUpdate();
        if (agent === selectedAgent) {
          onSelectAgent(agents[0] !== agent ? agents[0] : agents[1] || '');
        }
      } catch (error) {
        console.error('Error deleting agent:', error);
      }
    }
  };

  const handleStartRename = (agent: string) => {
    setEditingAgent(agent);
    setEditName(agent);
  };

  const handleRename = async () => {
    if (editingAgent && editName.trim() && editName !== editingAgent) {
      try {
        await renameAgent(editingAgent, editName);
        onAgentsUpdate();
        if (editingAgent === selectedAgent) {
          onSelectAgent(editName);
        }
        setEditingAgent(null);
      } catch (error) {
        console.error('Error renaming agent:', error);
      }
    }
  };

  return (
    <div className="agent-selector">
      <label htmlFor="agent-select">Choose an agent:</label>
      <select
        id="agent-select"
        value={selectedAgent}
        onChange={(e) => onSelectAgent(e.target.value)}
      >
        {agents.map(agent => (
          <option key={agent} value={agent}>{agent}</option>
        ))}
      </select>
      <div className="agent-controls">
        {agents.map(agent => (
          <div key={agent} className="agent-control">
            {editingAgent === agent ? (
              <div className="agent-rename">
                <input
                  type="text"
                  value={editName}
                  onChange={(e) => setEditName(e.target.value)}
                  onKeyPress={(e) => e.key === 'Enter' && handleRename()}
                />
                <button onClick={handleRename}>Save</button>
                <button onClick={() => setEditingAgent(null)}>Cancel</button>
              </div>
            ) : (
              <div className="agent-actions">
                <span>{agent}</span>
                <button onClick={() => handleStartRename(agent)}>Rename</button>
                <button onClick={() => handleDeleteAgent(agent)}>Delete</button>
                <button onClick={() => onResetHistory(agent)}>Reset History</button>
              </div>
            )}
            {agents.map(sourceAgent => sourceAgent !== agent && (
              <div key={`${agent}-${sourceAgent}`}>
                <input
                  type="checkbox"
                  id={`history-${agent}-${sourceAgent}`}
                  checked={(agentHistoryConfigs[agent] || []).includes(sourceAgent)}
                  onChange={(e) => handleHistorySelection(agent, sourceAgent, e.target.checked)}
                />
                <label htmlFor={`history-${agent}-${sourceAgent}`}>
                  Use {sourceAgent}'s history
                </label>
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className="add-agent-form">
        <input
          type="text"
          value={newAgentName}
          onChange={(e) => setNewAgentName(e.target.value)}
          placeholder="New agent name"
        />
        <button onClick={handleAddAgent}>Add Agent</button>
      </div>
    </div>
  );
};

export default AgentSelector;