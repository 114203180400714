import React, { useState, useEffect, useCallback, useRef } from 'react';
import MessageList from './MessageList';
import MessageInput from './MessageInput';
import AgentSelector from './AgentSelector';
import CustomInstructions from './CustomInstructions';
import MessageContext from './MessageContext';
import AgentGraph from './AgentGraph';
import { ImageGeneratorRef } from '../components/ImageGenerator';
import { 
  sendMessage, 
  updateHistory, 
  getAgents, 
  resetAgentHistory, 
  updateConnections, 
  getConnections,
  getCustomInstructions,
  updateCustomInstructions,
  addAgent,
  getHistory,
  getMessageContext,
  updateMessageContext,
  getAgentHistoryConfig,
  checkHistoryStatus
} from '../services/api';
import '../styles/ChatWindow.css';

interface Message {
  role: 'user' | 'assistant';
  content: string;
}

interface ChatState {
  messages: Message[];
  customInstructions: string;
  context1: string;
  context2: string;
  context1Enabled: boolean;
  context2Enabled: boolean;
}

interface ChatWindowProps {
  onAgentsUpdate: (agents: string[]) => void;
  onImageGenerated: (imageUrl: string) => void;
  imageGeneratorRef: React.RefObject<{queuePrompts: (agents?: string[]) => Promise<void>}>;
  onNewPrompt: (prompt: string) => void;  // Nowa linia
}

const ChatWindow: React.FC<ChatWindowProps> = ({ 
  onAgentsUpdate, 
  onImageGenerated,
  imageGeneratorRef,
  onNewPrompt  // Nowa linia
}) => {
  const [agents, setAgents] = useState<string[]>([]);
  const [selectedAgent, setSelectedAgent] = useState<string>('');
  const [chats, setChats] = useState<{ [key: string]: ChatState }>({});
  const [connections, setConnections] = useState<{ [key: string]: string[] }>({});
  const [isLoading, setIsLoading] = useState(false);
  const [autoGenerateEnabled, setAutoGenerateEnabled] = useState(true);
  const previousMessagesRef = useRef<Message[]>([]);

  const fetchAgents = useCallback(async () => {
    try {
      const fetchedAgents = await getAgents();
      setAgents(fetchedAgents);
      if (fetchedAgents.length > 0 && !selectedAgent) {
        setSelectedAgent(fetchedAgents[0]);
      }
      onAgentsUpdate(fetchedAgents);
    } catch (error) {
      console.error('Error fetching agents:', error);
    }
  }, [selectedAgent, onAgentsUpdate]);

  const fetchConnections = useCallback(async () => {
    try {
      const fetchedConnections = await getConnections();
      setConnections(fetchedConnections);
    } catch (error) {
      console.error('Error fetching connections:', error);
    }
  }, []);

  useEffect(() => {
    fetchAgents();
    fetchConnections();
  }, [fetchAgents, fetchConnections]);

  useEffect(() => {
    if (selectedAgent && !chats[selectedAgent]) {
      setChats(prevChats => ({
        ...prevChats,
        [selectedAgent]: {
          messages: [],
          customInstructions: '',
          context1: '',
          context2: '',
          context1Enabled: true,
          context2Enabled: true
        }
      }));
    }
  }, [selectedAgent, chats]);

  useEffect(() => {
    if (selectedAgent) {
      if (!chats[selectedAgent]?.customInstructions) {
        fetchCustomInstructions(selectedAgent);
      }
      if (!chats[selectedAgent]?.context1) {
        fetchMessageContext(selectedAgent, 'context1');
      }
      if (!chats[selectedAgent]?.context2) {
        fetchMessageContext(selectedAgent, 'context2');
      }
    }
  }, [selectedAgent, chats]);

  const fetchCustomInstructions = async (agent: string) => {
    try {
      const instructions = await getCustomInstructions(agent);
      setChats(prevChats => ({
        ...prevChats,
        [agent]: {
          ...prevChats[agent],
          customInstructions: instructions
        }
      }));
    } catch (error) {
      console.error('Error fetching custom instructions:', error);
    }
  };

  const fetchMessageContext = async (agent: string, contextId: string) => {
    try {
      const content = await getMessageContext(agent, contextId);
      setChats(prevChats => ({
        ...prevChats,
        [agent]: {
          ...prevChats[agent],
          [contextId]: content,
          [`${contextId}Enabled`]: true
        }
      }));
    } catch (error) {
      console.error(`Error fetching ${contextId}:`, error);
    }
  };

  const fetchAgentHistory = useCallback(async (agent: string) => {
    try {
      const history = await getHistory(agent);
      const messages = parseHistoryToMessages(history);
      
      if (JSON.stringify(messages) !== JSON.stringify(previousMessagesRef.current)) {
        setChats(prevChats => ({
          ...prevChats,
          [agent]: {
            ...prevChats[agent],
            messages: messages
          }
        }));
        previousMessagesRef.current = messages;
      }
    } catch (error) {
      console.error('Error fetching agent history:', error);
    }
  }, []);

  useEffect(() => {
    if (selectedAgent) {
      fetchAgentHistory(selectedAgent);
      const intervalId = setInterval(() => fetchAgentHistory(selectedAgent), 5000);
      return () => clearInterval(intervalId);
    }
  }, [selectedAgent, fetchAgentHistory]);

  const handleConnectionChange = async (newConnections: { [key: string]: string[] }) => {
    setConnections(newConnections);
    try {
      await updateConnections(newConnections);
    } catch (error) {
      console.error('Error updating connections:', error);
    }
  };

  const handleAddAgent = async (agentName: string) => {
    try {
      await addAgent(agentName);
      fetchAgents();
    } catch (error) {
      console.error('Error adding new agent:', error);
    }
  };

  const handleSendMessage = useCallback(async (content: string) => {
    try {
      setIsLoading(true);
      const chat = chats[selectedAgent];
      
      let fullMessageForApi = content;

      const selectedHistories = await getAgentHistoryConfig(selectedAgent);
      
      if (selectedHistories && selectedHistories.length > 0) {
        let combinedHistory = "Niżej przeczytasz historię rozmowy z innymi agentami, co może dać Ci odpowiednie informacje do wykonania zadania:\n\n";
        
        for (const agentName of selectedHistories) {
          const agentHistory = await getHistory(agentName);
          combinedHistory += `Historia agenta ${agentName}:\n${agentHistory}\n\n`;
        }
        
        fullMessageForApi = combinedHistory + "\nTwoja wiadomość:\n" + content;
      }
      
      if (chat.context1Enabled && chat.context1) {
        fullMessageForApi = `${chat.context1}\n\n${fullMessageForApi}`;
      }
      
      if (chat.context2Enabled && chat.context2) {
        fullMessageForApi = `${fullMessageForApi}\n\n${chat.context2}`;
      }
      
      setChats(prevChats => ({
        ...prevChats,
        [selectedAgent]: {
          ...prevChats[selectedAgent],
          messages: [
            ...prevChats[selectedAgent].messages,
            { role: 'user', content: content }
          ]
        }
      }));

      const response = await sendMessage(fullMessageForApi, selectedAgent, []);
      
      setChats(prevChats => ({
        ...prevChats,
        [selectedAgent]: {
          ...prevChats[selectedAgent],
          messages: [
            ...prevChats[selectedAgent].messages,
            { role: 'assistant', content: response.content }
          ]
        }
      }));

      const updatedHistory = [
        ...chats[selectedAgent].messages,
        { role: 'user', content: content },
        { role: 'assistant', content: response.content }
      ]
        .map(msg => `${msg.role === 'user' ? 'User' : 'AI'}: ${msg.content}`)
        .join('\n');
      
      await updateHistory(selectedAgent, updatedHistory);

      if (response.content.includes('<prompt>')) {
        const promptMatch = response.content.match(/<prompt>(.*?)<\/prompt>/s);
        if (promptMatch) {
          onNewPrompt(promptMatch[1].trim());  // Nowa linia
        }
      }

      const currentConnections = connections[selectedAgent] || [];
      if (currentConnections.length === 0 && autoGenerateEnabled) {
        const waitForHistory = async () => {
          let attempts = 0;
          const maxAttempts = 10;
          
          while (attempts < maxAttempts) {
            const isReady = await checkHistoryStatus(selectedAgent);
            if (isReady) {
              console.log('Historia została zapisana, uruchamiam generator');
              if (imageGeneratorRef.current) {
                imageGeneratorRef.current.queuePrompts();
              }
              break;
            }
            console.log('Czekam na zapisanie historii...');
            await new Promise(resolve => setTimeout(resolve, 1000));
            attempts++;
          }
          
          if (attempts >= maxAttempts) {
            console.error('Nie udało się potwierdzić zapisu historii');
          }
        };

        await waitForHistory();
      }

    } catch (error) {
      console.error('Error sending message:', error);
    } finally {
      setIsLoading(false);
    }
  }, [chats, selectedAgent, connections, autoGenerateEnabled, imageGeneratorRef, onNewPrompt]);

  const handleEditMessage = async (index: number, newContent: string) => {
    setChats(prevChats => {
      const newMessages = [...(prevChats[selectedAgent]?.messages || [])];
      newMessages[index].content = newContent;
      return {
        ...prevChats,
        [selectedAgent]: {
          ...prevChats[selectedAgent],
          messages: newMessages
        }
      };
    });

    const history = chats[selectedAgent]?.messages.map(msg => `${msg.role === 'user' ? 'User' : 'AI'}: ${msg.content}`).join('\n') || '';
    await updateHistory(selectedAgent, history);
  };

  const handleUpdateCustomInstructions = async (agent: string, newInstructions: string) => {
    try {
      await updateCustomInstructions(agent, newInstructions);
      setChats(prevChats => ({
        ...prevChats,
        [agent]: {
          ...prevChats[agent],
          customInstructions: newInstructions
        }
      }));
    } catch (error) {
      console.error('Error updating custom instructions:', error);
    }
  };

  const handleUpdateMessageContext = async (
    agent: string,
    contextId: string,
    content: string
  ) => {
    try {
      await updateMessageContext(agent, contextId, content);
      setChats(prevChats => ({
        ...prevChats,
        [agent]: {
          ...prevChats[agent],
          [contextId]: content
        }
      }));
    } catch (error) {
      console.error('Error updating message context:', error);
    }
  };

  const handleToggleContext = (contextId: 'context1' | 'context2', enabled: boolean) => {
    setChats(prevChats => ({
      ...prevChats,
      [selectedAgent]: {
        ...prevChats[selectedAgent],
        [`${contextId}Enabled`]: enabled
      }
    }));
  };

  return (
    <div className="chat-window">
      <div className="chat-window-layout">
        <div className="chat-window-sidebar">
          <div className="auto-generate-control">
            <label>
              <input
                type="checkbox"
                checked={autoGenerateEnabled}
                onChange={(e) => setAutoGenerateEnabled(e.target.checked)}
              />
              Auto-generate images after agent chain
            </label>
          </div>
          <AgentSelector
            agents={agents}
            selectedAgent={selectedAgent}
            onSelectAgent={setSelectedAgent}
            onResetHistory={resetAgentHistory}
            onAddAgent={handleAddAgent}
            onAgentsUpdate={fetchAgents}
          />
        </div>
        <div className="chat-window-graph">
          <AgentGraph
            agents={agents}
            connections={connections}
            onConnectionChange={handleConnectionChange}
            onAddAgent={handleAddAgent}
            onSelectAgent={setSelectedAgent}
          />
        </div>
      </div>
      {selectedAgent && chats[selectedAgent] && (
        <>
          {selectedAgent && chats[selectedAgent] && (
            <CustomInstructions
              agent={selectedAgent}
              instructions={chats[selectedAgent].customInstructions}
              onUpdateInstructions={(instructions) => handleUpdateCustomInstructions(selectedAgent, instructions)}
            />
          )}
          <MessageList 
            messages={chats[selectedAgent].messages || []} 
            onEditMessage={(index, newContent) => handleEditMessage(index, newContent)} 
          />
          <MessageContext
            contextId="context1"
            agent={selectedAgent}
            content={chats[selectedAgent].context1 || ''}
            isEnabled={chats[selectedAgent].context1Enabled}
            onUpdateContent={(content) => handleUpdateMessageContext(selectedAgent, 'context1', content)}
            onToggleEnabled={(enabled) => handleToggleContext('context1', enabled)}
          />
          <MessageInput onSend={handleSendMessage} disabled={isLoading} />
          {isLoading && <div className="loading-indicator">Loading...</div>}
          <MessageContext
            contextId="context2"
            agent={selectedAgent}
            content={chats[selectedAgent].context2 || ''}
            isEnabled={chats[selectedAgent].context2Enabled}
            onUpdateContent={(content) => handleUpdateMessageContext(selectedAgent, 'context2', content)}
            onToggleEnabled={(enabled) => handleToggleContext('context2', enabled)}
          />
        </>
      )}
    </div>
  );
};

const parseHistoryToMessages = (history: string): Message[] => {
  const lines = history.split('\n');
  const messages: Message[] = [];
  let currentMessage: Message | null = null;

  for (const line of lines) {
    if (line.startsWith('User: ')) {
      if (currentMessage) {
        messages.push(currentMessage);
      }
      currentMessage = { role: 'user', content: line.slice(6) };
    } else if (line.startsWith('AI: ')) {
      if (currentMessage) {
        messages.push(currentMessage);
      }
      currentMessage = { role: 'assistant', content: line.slice(4) };
    } else if (currentMessage) {
      currentMessage.content += '\n' + line;
    }
  }

  if (currentMessage) {
    messages.push(currentMessage);
  }

  return messages;
};

export default ChatWindow;