import React, { useState } from 'react';

interface Message {
  role: 'user' | 'assistant';
  content: string;
}

interface MessageListProps {
  messages: Message[];
  onEditMessage: (index: number, newContent: string) => void;
}

const MessageList: React.FC<MessageListProps> = ({ messages, onEditMessage }) => {
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [editContent, setEditContent] = useState('');

  const handleEditClick = (index: number) => {
    setEditingIndex(index);
    setEditContent(messages[index].content);
  };

  const handleSaveEdit = (index: number) => {
    onEditMessage(index, editContent);
    setEditingIndex(null);
  };

  return (
    <div className="message-list">
      {messages.map((message, index) => (
        <div key={index} className={`message ${message.role}`}>
          <span className="message-role">{message.role === 'user' ? 'You' : 'AI'}:</span>
          {editingIndex === index ? (
            <>
              <textarea
                value={editContent}
                onChange={(e) => setEditContent(e.target.value)}
              />
              <button onClick={() => handleSaveEdit(index)}>Save</button>
              <button onClick={() => setEditingIndex(null)}>Cancel</button>
            </>
          ) : (
            <>
              <p className="message-content">{message.content}</p>
              <button onClick={() => handleEditClick(index)}>Edit</button>
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default MessageList;