import React, { useState } from 'react';
import { searchQuestions } from '../services/api';
import AgentSumQuestions from './AgentSumQuestions';

interface SearchResult {
  title: string;
  description?: string;
  url?: string;
  snippet?: string;
}

interface KeywordQuestion {
  question: string;
  searchVolume: number;
  monthlyTrend?: Array<{
    year: number;
    month: number;
    volume: number;
  }>;
  competitionLevel?: string;
}

interface AgentSumResponse {
  tofuQuestions: string[];
  mofuQuestions: string[];
}

interface KeywordSearchState {
  keyword: string;
  searchResults: SearchResult[];
  relatedQuestions: {
    whatQuestions: KeywordQuestion[];
    howQuestions: KeywordQuestion[];
  };
  agentSumQuestions?: AgentSumResponse;
  isLoading: boolean;
  error: string | null;
}

const KeywordSearch: React.FC = () => {
  const [state, setState] = useState<KeywordSearchState>({
    keyword: '',
    searchResults: [],
    relatedQuestions: {
      whatQuestions: [],
      howQuestions: []
    },
    agentSumQuestions: undefined,
    isLoading: false,
    error: null
  });

  const handleSearch = async () => {
    if (!state.keyword.trim()) {
      setState(prev => ({ ...prev, error: 'Proszę wpisać słowo kluczowe' }));
      return;
    }

    setState(prev => ({ ...prev, isLoading: true, error: null }));
    try {
      const response = await searchQuestions(state.keyword);
      console.log('Received response:', response);
      
      setState(prev => ({
        ...prev,
        searchResults: response.searchResults || [],
        relatedQuestions: {
          whatQuestions: response.relatedQuestions.whatQuestions || [],
          howQuestions: response.relatedQuestions.howQuestions || []
        },
        agentSumQuestions: response.agentSumQuestions,
        isLoading: false,
        error: null
      }));
      
    } catch (error) {
      console.error('Search error:', error);
      setState(prev => ({
        ...prev,
        searchResults: [],
        relatedQuestions: {
          whatQuestions: [],
          howQuestions: []
        },
        agentSumQuestions: undefined,
        isLoading: false,
        error: error instanceof Error ? error.message : 'Wystąpił błąd podczas wyszukiwania'
      }));
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !state.isLoading) {
      handleSearch();
    }
  };

  return (
    <div className="keyword-search-container">
      <div className="keyword-search">
        <div className="search-input">
          <input
            type="text"
            value={state.keyword}
            onChange={(e) => setState(prev => ({ ...prev, keyword: e.target.value }))}
            onKeyPress={handleKeyPress}
            placeholder="Wpisz słowo kluczowe..."
            disabled={state.isLoading}
          />
          <button 
            onClick={handleSearch} 
            disabled={state.isLoading || !state.keyword.trim()}
          >
            {state.isLoading ? 'Wyszukiwanie...' : 'Szukaj'}
          </button>
        </div>

        {state.error && (
          <div className="error-message">
            {state.error}
          </div>
        )}

        {state.isLoading && (
          <div className="loading-indicator">
            Wyszukiwanie informacji o "{state.keyword}"...
          </div>
        )}

        <div className="search-results-container">
          {/* Sekcja wyników wyszukiwania */}
          {!state.isLoading && (
            <div className="search-results">
              {/* Sekcja pytań "Co to?" */}
              {state.relatedQuestions.whatQuestions.length > 0 && (
                <div className="questions-section what-questions">
                  <h3>Najczęściej zadawane pytania "Co to?":</h3>
                  <div className="questions-list">
                    {state.relatedQuestions.whatQuestions.map((item, index) => (
                      <div key={index} className="question-item">
                        <div className="question-main">
                          <span className="question">{item.question}</span>
                          <span className="search-volume">
                            {item.searchVolume.toLocaleString()} wyszukań/mies.
                          </span>
                        </div>
                        {item.competitionLevel && (
                          <div className="competition-level">
                            Poziom konkurencji: {item.competitionLevel}
                          </div>
                        )}
                        {item.monthlyTrend && item.monthlyTrend.length > 0 && (
                          <div className="monthly-trend">
                            <h4>Trend miesięczny:</h4>
                            <div className="trend-data">
                              {item.monthlyTrend.map((month, idx) => (
                                <div key={idx} className="trend-item">
                                  {month.year}/{month.month}: {month.volume.toLocaleString()} wyszukań
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {/* Sekcja pytań "Jak?" */}
              {state.relatedQuestions.howQuestions.length > 0 && (
                <div className="questions-section how-questions">
                  <h3>Najczęściej zadawane pytania "Jak?":</h3>
                  <div className="questions-list">
                    {state.relatedQuestions.howQuestions.map((item, index) => (
                      <div key={index} className="question-item">
                        <div className="question-main">
                          <span className="question">{item.question}</span>
                          <span className="search-volume">
                            {item.searchVolume.toLocaleString()} wyszukań/mies.
                          </span>
                        </div>
                        {item.competitionLevel && (
                          <div className="competition-level">
                            Poziom konkurencji: {item.competitionLevel}
                          </div>
                        )}
                        {item.monthlyTrend && item.monthlyTrend.length > 0 && (
                          <div className="monthly-trend">
                            <h4>Trend miesięczny:</h4>
                            <div className="trend-data">
                              {item.monthlyTrend.map((month, idx) => (
                                <div key={idx} className="trend-item">
                                  {month.year}/{month.month}: {month.volume.toLocaleString()} wyszukań
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {/* Sekcja wyników wyszukiwania */}
              {state.searchResults.length > 0 && (
                <div className="articles-section">
                  <h3>Znalezione artykuły:</h3>
                  <div className="articles-list">
                    {state.searchResults.map((result, index) => (
                      <div key={index} className="article-item">
                        <h4>{result.title}</h4>
                        {result.snippet && <p className="snippet">{result.snippet}</p>}
                        {result.description && <p className="description">{result.description}</p>}
                        {result.url && (
                          <a href={result.url} target="_blank" rel="noopener noreferrer" className="read-more">
                            Czytaj więcej
                          </a>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {state.searchResults.length === 0 && state.keyword && !state.isLoading && (
                <div className="no-results">
                  Nie znaleziono wyników dla "{state.keyword}"
                </div>
              )}
            </div>
          )}

          {/* Sekcja pytań od AgentSum */}
          {!state.isLoading && state.agentSumQuestions && (
            <AgentSumQuestions 
              tofuQuestions={state.agentSumQuestions.tofuQuestions}
              mofuQuestions={state.agentSumQuestions.mofuQuestions}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default KeywordSearch;