import React, { useState, useEffect } from 'react';

interface CustomInstructionsProps {
  agent: string;
  instructions: string;
  onUpdateInstructions: (instructions: string) => Promise<void>;
}

const CustomInstructions: React.FC<CustomInstructionsProps> = ({
  agent,
  instructions,
  onUpdateInstructions
}) => {
  const [localInstructions, setLocalInstructions] = useState(instructions);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setLocalInstructions(instructions);
  }, [instructions]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setLocalInstructions(instructions);
  };

  const handleSave = async () => {
    try {
      await onUpdateInstructions(localInstructions);
      setIsEditing(false);
    } catch (error) {
      console.error('Error saving instructions:', error);
    }
  };

  return (
    <div className="custom-instructions">
      <div className="instructions-header">
        <h3>Custom Instructions for {agent}</h3>
      </div>
      {isEditing ? (
        <div className="instructions-edit">
          <textarea
            value={localInstructions}
            onChange={(e) => setLocalInstructions(e.target.value)}
            placeholder="Enter custom instructions for this agent..."
            rows={5}
            className="instructions-textarea"
          />
          <div className="button-group">
            <button 
              className="save-button"
              onClick={handleSave}
            >
              Save
            </button>
            <button 
              className="cancel-button"
              onClick={handleCancel}
            >
              Cancel
            </button>
          </div>
        </div>
      ) : (
        <div className="instructions-view">
          <p className="instructions-content">
            {instructions ? (
              <>
                {instructions.slice(0, 100)}
                {instructions.length > 100 && (
                  <span className="truncated-indicator">...</span>
                )}
              </>
            ) : (
              <span className="no-instructions">
                No custom instructions set.
              </span>
            )}
          </p>
          <button 
            className="edit-button"
            onClick={handleEdit}
          >
            Edit Instructions
          </button>
        </div>
      )}
    </div>
  );
};

export default CustomInstructions;