import React, { useState, useEffect, useCallback } from 'react';
import { deleteImage } from '../services/api';
import '../styles/ImageHistory.css';

interface ImageFile {
  name: string;
  path: string;
  type: 'generated' | 'upscaled';
  date: string;
  size: number;
}

interface ImageHistoryProps {
  onSelectForUpscale: (imagePath: string) => void;
  onSelectForImageToImage: (imagePath: string) => void;
}

const ImageHistory: React.FC<ImageHistoryProps> = ({ 
  onSelectForUpscale, 
  onSelectForImageToImage 
}) => {
  const [images, setImages] = useState<ImageFile[]>([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isDeletingMap, setIsDeletingMap] = useState<{ [key: string]: boolean }>({});

  const fetchImages = useCallback(async () => {
    try {
      setIsLoading(true);
      setError(null);
      
      const queryParams = new URLSearchParams();
      if (startDate) queryParams.append('startDate', startDate);
      if (endDate) queryParams.append('endDate', endDate);
      if (searchQuery) queryParams.append('searchQuery', searchQuery);

      console.log('Fetching images...');
      const response = await fetch(`http://localhost:5000/images?${queryParams}`);
      
      if (!response.ok) {
        throw new Error('Failed to fetch images');
      }
      
      const data = await response.json();
      setImages(data);
    } catch (error) {
      console.error('Error fetching images:', error);
      setError(error instanceof Error ? error.message : 'An error occurred');
    } finally {
      setIsLoading(false);
    }
  }, [startDate, endDate, searchQuery]);

  useEffect(() => {
    fetchImages();
  }, [fetchImages]);

  const handleDeleteImage = async (image: ImageFile) => {
    if (!window.confirm('Are you sure you want to delete this image?')) {
      return;
    }

    try {
      setIsDeletingMap(prev => ({ ...prev, [image.name]: true }));
      const filename = image.name;
      const type = image.type;
      
      await deleteImage(type, filename);
      
      // Odśwież listę obrazów
      await fetchImages();
      
    } catch (error) {
      console.error('Error deleting image:', error);
      setError('Failed to delete image');
    } finally {
      setIsDeletingMap(prev => ({ ...prev, [image.name]: false }));
    }
  };

  const formatDate = (date: string) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const formatFileSize = (bytes: number) => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    if (bytes === 0) return '0 Byte';
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString());
    return Math.round((bytes / Math.pow(1024, i))) + ' ' + sizes[i];
  };

  return (
    <div className="image-history">
      <div className="filters">
        <div className="date-filters">
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            placeholder="Start Date"
          />
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            placeholder="End Date"
          />
        </div>
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Search by filename..."
          className="search-input"
        />
      </div>

      {isLoading && <div className="loading">Loading images...</div>}
      {error && <div className="error">{error}</div>}

      <div className="image-grid">
        {images.map((image) => (
          <div key={image.path} className="image-card">
            <img 
              src={`http://localhost:5000${image.path}`}
              alt={image.name}
              loading="lazy"
            />
            <div className="image-info">
              <div className="image-name">{image.name}</div>
              <div className="image-details">
                <span>{formatDate(image.date)}</span>
                <span>{formatFileSize(image.size)}</span>
                <span className="image-type">{image.type}</span>
              </div>
            </div>
            <div className="image-actions">
              <button 
                onClick={() => onSelectForUpscale(`http://localhost:5000${image.path}`)}
                className="upscale-button"
              >
                Upscale
              </button>
              <button 
                onClick={() => onSelectForImageToImage(`http://localhost:5000${image.path}`)}
                className="img2img-button"
              >
                Use in Generator
              </button>
              <button
                onClick={() => handleDeleteImage(image)}
                className="delete-button"
                disabled={isDeletingMap[image.name]}
              >
                {isDeletingMap[image.name] ? 'Deleting...' : 'Delete'}
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageHistory;