import React, { useState } from 'react';

interface MessageInputProps {
  onSend: (message: string) => void;
  disabled?: boolean;
}

const MessageInput: React.FC<MessageInputProps> = ({ onSend, disabled }) => {
  const [message, setMessage] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (message.trim() && !disabled) {
      onSend(message);
      setMessage('');
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value);
    
    // Automatyczne dostosowanie wysokości textarea
    const textarea = e.target;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  return (
    <div className="message-context">
      <div className="context-header">
        <h3>Message Input</h3>
        <div className="context-enabled">
          <button 
            type="submit" 
            onClick={handleSubmit}
            disabled={disabled || !message.trim()}
            className={`send-button ${disabled || !message.trim() ? 'disabled' : ''}`}
          >
            Send
          </button>
        </div>
      </div>
      
      <form className="message-input" onSubmit={handleSubmit}>
        <div className="textarea-wrapper">
          <textarea
            value={message}
            onChange={handleTextareaChange}
            onKeyDown={handleKeyDown}
            placeholder="Type your message here... (Shift+Enter for new line, Enter to send)"
            disabled={disabled}
            rows={1}
            className={`context-textarea ${disabled ? 'disabled' : ''}`}
          />
          <div className="context-info">
            {message.length > 0 && (
              <span className="char-count">({message.length} characters)</span>
            )}
            <span className="input-hint">Press Enter to send, Shift+Enter for new line</span>
          </div>
        </div>
      </form>
    </div>
  );
};

export default MessageInput;