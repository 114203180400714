import React, { useState, useEffect } from 'react';

interface MessageContextProps {
  contextId: 'context1' | 'context2';
  agent: string;
  content: string;
  isEnabled: boolean;
  onUpdateContent: (content: string) => Promise<void>;
  onToggleEnabled: (enabled: boolean) => void;
}

const MessageContext: React.FC<MessageContextProps> = ({
  contextId,
  agent,
  content,
  isEnabled,
  onUpdateContent,
  onToggleEnabled
}) => {
  const [localContent, setLocalContent] = useState(content);
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setLocalContent(content);
  }, [content]);

  const handleEdit = () => {
    setIsEditing(true);
    setError(null);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setLocalContent(content);
    setError(null);
  };

  const handleSave = async () => {
    try {
      setIsSaving(true);
      setError(null);
      await onUpdateContent(localContent);
      setIsEditing(false);
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Error saving context');
    } finally {
      setIsSaving(false);
    }
  };

  const getContextTitle = () => {
    return contextId === 'context1' 
      ? 'Pre-Message Context (Added before user message)'
      : 'Post-Message Context (Added after user message)';
  };

  const getPlaceholderText = () => {
    return contextId === 'context1'
      ? 'Enter pre-message context here...'
      : 'Enter post-message context here...';
  };

  const getNoContentText = () => {
    return `No ${contextId === 'context1' ? 'pre' : 'post'}-message context set.`;
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && e.shiftKey) {
      // Allow Shift+Enter for new lines
      return;
    }
    if (e.key === 'Escape') {
      handleCancel();
    }
  };

  return (
    <div className="message-context">
      <div className="context-header">
        <h3>{getContextTitle()}</h3>
        <div className="context-enabled">
          <label>
            Context Enabled
            <input
              type="checkbox"
              checked={isEnabled}
              onChange={(e) => onToggleEnabled(e.target.checked)}
            />
          </label>
        </div>
      </div>
      
      {error && (
        <div className="error-message">
          {error}
        </div>
      )}

      {isEditing ? (
        <div className="context-edit">
          <textarea
            value={localContent}
            onChange={(e) => setLocalContent(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder={getPlaceholderText()}
            rows={5}
            disabled={isSaving}
            className="context-textarea"
          />
          <div className="button-group">
            <button 
              className="save-button"
              onClick={handleSave}
              disabled={isSaving}
            >
              {isSaving ? 'Saving...' : 'Save'}
            </button>
            <button 
              className="cancel-button"
              onClick={handleCancel}
              disabled={isSaving}
            >
              Cancel
            </button>
          </div>
        </div>
      ) : (
        <div className="context-view">
          <div className="context-preview">
            {content ? (
              <>
                <p className="context-content">
                  {content.slice(0, 100)}
                  {content.length > 100 && (
                    <span className="context-ellipsis">...</span>
                  )}
                </p>
                {content.length > 100 && (
                  <span className="context-length">
                    ({content.length} characters)
                  </span>
                )}
              </>
            ) : (
              <p className="context-empty">{getNoContentText()}</p>
            )}
          </div>
          <button 
            className="edit-button"
            onClick={handleEdit}
          >
            Edit Context
          </button>
        </div>
      )}
    </div>
  );
};

export default MessageContext;