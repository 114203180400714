import React, { useState, useEffect, forwardRef, useImperativeHandle, useCallback } from 'react';
import { fal } from "@fal-ai/client";
import { getAgentPrompts, saveGeneratedImages } from '../services/api';
import '../styles/ImageGenerator.css';

export interface ImageGeneratorRef {
  queuePrompts: (agents?: string[]) => Promise<void>;
  setImageToImage: (imagePath: string) => void;
}

interface GeneratedImage {
  url: string;
  contentType: string;
}

interface ImageSize {
  width: number;
  height: number;
}

interface LoraWeight {
  path: string;
  scale: number | { [key: string]: number };
}

interface ControlNet {
  path: string;
  configUrl?: string;
  variant?: string;
  controlImageUrl: string;
  maskImageUrl?: string;
  maskThreshold: number;
  conditioningScale: number;
  startPercentage: number;
  endPercentage: number;
}

interface IPAdapter {
  path: string;
  subfolder?: string;
  weightName?: string;
  imageEncoderPath?: string;
  imageEncoderSubfolder?: string;
  imageEncoderWeightName?: string;
  imageUrl: string;
  maskImageUrl?: string;
  maskThreshold: number;
  scale: number;
}

interface GenerationSettings {
  mode: 'text2img' | 'img2img' | 'inpainting';
  prompt: string;
  negativePrompt?: string;
  imageSize: string | ImageSize;
  numInferenceSteps: number;
  guidanceScale: number;
  realCfgScale: number;
  useRealCfg: boolean;
  numImages: number;
  enableSafetyChecker: boolean;
  seed?: number;
  strength?: number;  // Dla img2img/inpainting
  imageUrl?: string;  // Dla głównego obrazu w img2img/inpainting
  maskImageUrl?: string;  // Dla inpainting
  referenceImageUrl?: string;  // Dla reference image
  referenceStrength?: number;  // Dla reference image
  referenceStart?: number;  // Dla reference image
  referenceEnd?: number;  // Dla reference image
  loras: LoraWeight[];
  controlnets: ControlNet[];
  ipAdapters: IPAdapter[];
}

interface AgentPrompt {
  agentName: string;
  prompt: string;
}

interface ImageGeneratorProps {
  agents: string[];
  initialImageUrl: string | null;
  apiKey: string;
  isApiConnected: boolean;
  onApiKeyChange: (newKey: string) => void;
  lastGeneratedPrompt: string | null;
  selectedImage: string | null;
}

const defaultSettings: GenerationSettings = {
  mode: 'text2img',
  prompt: '',
  imageSize: 'square_hd',
  numInferenceSteps: 28,
  guidanceScale: 3.5,
  realCfgScale: 3.5,
  useRealCfg: false,
  numImages: 1,
  enableSafetyChecker: true,
  loras: [],
  controlnets: [],
  ipAdapters: []
};

const ImageGenerator = forwardRef<ImageGeneratorRef, ImageGeneratorProps>((props, ref) => {
  const { agents, initialImageUrl, apiKey, isApiConnected, onApiKeyChange, lastGeneratedPrompt, selectedImage } = props;
  
  const [settings, setSettings] = useState<GenerationSettings>(defaultSettings);
  const [savedSettings, setSavedSettings] = useState<{ [key: string]: GenerationSettings }>({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [generatedImages, setGeneratedImages] = useState<GeneratedImage[]>([]);
  const [customSize, setCustomSize] = useState<ImageSize>({ width: 512, height: 512 });
  const [settingsName, setSettingsName] = useState('');
  const [selectedAgents, setSelectedAgents] = useState<string[]>([]);
  const [promptQueue, setPromptQueue] = useState<AgentPrompt[]>([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [pendingPrompt, setPendingPrompt] = useState<string | null>(null);
  const [isGenerating, setIsGenerating] = useState(false);

  const handleGenerate = async (forcedPrompt?: string) => {
    if (!isApiConnected || isGenerating) {  // Dodajemy sprawdzenie flagi
      setError('Please connect to the API first');
      return;
    }

    const promptToUse = forcedPrompt || settings.prompt;
    console.log('Używany prompt do generowania:', promptToUse);

    if (!promptToUse.trim()) {
      setError('Please enter a prompt');
      return;
    }

    setIsGenerating(true);  // Ustawiamy flagę
    setIsLoading(true);
    setError(null);

    try {
      let input;
      let endpoint = "fal-ai/flux-general";

      if (settings.mode === 'img2img' && settings.imageUrl) {
        endpoint = "fal-ai/flux-general/image-to-image";
        input = {
          ...prepareApiInput(),
          prompt: promptToUse,
          num_images: 1,  // Wymuszamy jeden obraz
          image_url: settings.imageUrl,
          strength: settings.strength || 0.85,
          reference_image_url: settings.referenceImageUrl,
          reference_strength: settings.referenceStrength || 0.65,
          reference_start: settings.referenceStart,
          reference_end: settings.referenceEnd || 1
        };
      } else if (settings.mode === 'inpainting' && settings.imageUrl && settings.maskImageUrl) {
        endpoint = "fal-ai/flux-general/image-to-image";
        input = {
          ...prepareApiInput(),
          prompt: promptToUse,
          num_images: 1,  // Wymuszamy jeden obraz
          image_url: settings.imageUrl,
          mask_image_url: settings.maskImageUrl,
          strength: settings.strength || 1.0,
          reference_image_url: settings.referenceImageUrl,
          reference_strength: settings.referenceStrength || 0.65,
          reference_start: settings.referenceStart,
          reference_end: settings.referenceEnd || 1
        };
      } else {
        input = {
          ...prepareApiInput(),
          prompt: promptToUse,
          num_images: 1,  // Wymuszamy jeden obraz
          reference_image_url: settings.referenceImageUrl,
          reference_strength: settings.referenceStrength || 0.65,
          reference_start: settings.referenceStart,
          reference_end: settings.referenceEnd || 1
        };
      }
      
      console.log('Dokładne parametry wysyłane do API:', input);

      const result = await fal.subscribe(
        endpoint,
        {
          input,
          logs: true,
          onQueueUpdate: (update) => {
            if (update.status === "IN_PROGRESS") {
              update.logs?.map((log) => log.message).forEach(console.log);
            }
          },
        }
      );

      if (result.data) {
        const newImages = result.data.images || [];
        setGeneratedImages(prev => [...prev, ...newImages]);
        await saveGeneratedImages(newImages);

        // Zamiast od razu ustawiać nowy pendingPrompt, 
        // po prostu aktualizujemy settings i kolejkę
        if (promptQueue.length > 0) {
          const nextPrompt = promptQueue[0].prompt;
          setSettings(prev => ({ ...prev, prompt: nextPrompt }));
          setPromptQueue(prev => prev.slice(1));
          
          // Dodajemy małe opóźnienie przed ustawieniem następnego promptu
          setTimeout(() => {
            setPendingPrompt(nextPrompt);
          }, 1000);
        }
      } else {
        throw new Error('No data received from API');
      }
    } catch (error) {
      console.error('Generation error:', error);
      setError(error instanceof Error ? error.message : 'Unknown error occurred');
    } finally {
      setIsLoading(false);
      setIsGenerating(false);  // Resetujemy flagę
    }
  };

  const queuePrompts = useCallback(async (specificAgents?: string[]) => {
    const agentsToUse = specificAgents || selectedAgents;
    console.log('queuePrompts wywołane dla agentów:', agentsToUse);
    
    if (agentsToUse.length === 0) {
      console.log('Brak agentów do przetworzenia');
      return;
    }

    try {
      console.log('Pobieranie promptów dla agentów:', agentsToUse);
      const prompts = await getAgentPrompts(agentsToUse);
      console.log('Otrzymane prompty:', prompts);

      if (prompts && prompts.length > 0) {
        const firstPrompt = prompts[0].prompt;
        console.log('Ustawianie pierwszego promptu:', firstPrompt);
        
        setSettings(prev => ({ ...prev, prompt: firstPrompt }));
        setPendingPrompt(firstPrompt);
        
        if (prompts.length > 1) {
          setPromptQueue(prompts.slice(1));
        }
      }
    } catch (error) {
      console.error('Error in queuePrompts:', error);
      setError('Failed to fetch prompts from agents');
    }
  }, [selectedAgents]);

  useImperativeHandle(ref, () => ({
    queuePrompts,
    setImageToImage: async (imagePath: string) => {
      try {
        // Pobieramy obraz jako blob
        const response = await fetch(imagePath);
        const blob = await response.blob();
        
        // Tworzymy File z blob
        const file = new File([blob], 'image.png', { type: 'image/png' });
        
        // Uploadujemy do FAL.ai storage
        const url = await fal.storage.upload(file);
        
        // Ustawiamy stan
        setSettings(prev => ({
          ...prev,
          mode: 'img2img',
          imageUrl: url
        }));
      } catch (error) {
        console.error('Error processing image:', error);
        setError('Failed to process image');
      }
    }
  }), []);

  useEffect(() => {
    if (lastGeneratedPrompt) {
      console.log('Otrzymano nowy prompt:', lastGeneratedPrompt);
      setSettings(prev => ({ ...prev, prompt: lastGeneratedPrompt }));
      setPendingPrompt(lastGeneratedPrompt);
    }
  }, [lastGeneratedPrompt]);

  useEffect(() => {
    if (pendingPrompt && !isLoading) {
      console.log('Generowanie obrazu dla promptu:', pendingPrompt);
      handleGenerate(pendingPrompt);
      setPendingPrompt(null);
    }
  }, [pendingPrompt, isLoading]);

  useEffect(() => {
    if (initialImageUrl) {
      setGeneratedImages([{ url: initialImageUrl, contentType: 'image/png' }]);
    }
  }, [initialImageUrl]);

  useEffect(() => {
    const saved = localStorage.getItem('imageGeneratorSettings');
    if (saved) {
      setSavedSettings(JSON.parse(saved));
    }
  }, []);

  useEffect(() => {
    if (selectedImage) {
      setSettings(prev => ({
        ...prev,
        mode: 'img2img',
        imageUrl: selectedImage
      }));
    }
  }, [selectedImage]);


  const handleApiKeySubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onApiKeyChange(apiKey);
  };

  type FileUploadType = 'main' | 'mask' | 'reference' | 'control' | 'ipAdapter';

  const handleFileUpload = async (file: File, type: FileUploadType) => {
    try {
      const url = await fal.storage.upload(file);
      switch (type) {
        case 'main':
          setSettings(prev => ({ 
            ...prev, 
            mode: 'img2img',  // Dodajemy automatyczne przełączenie trybu
            imageUrl: url 
          }));
          break;
        case 'mask':
          setSettings(prev => ({ ...prev, maskImageUrl: url }));
          break;
        case 'reference':
          setSettings(prev => ({ ...prev, referenceImageUrl: url }));
          break;
        case 'control':
          setSettings(prev => ({
            ...prev,
            controlnets: [...prev.controlnets, {
              path: '',
              controlImageUrl: url,
              maskThreshold: 0.5,
              conditioningScale: 1.0,
              startPercentage: 0,
              endPercentage: 1
            }]
          }));
          break;
        case 'ipAdapter':
          setSettings(prev => ({
            ...prev,
            ipAdapters: [...prev.ipAdapters, {
              path: '',
              imageUrl: url,
              maskThreshold: 0.5,
              scale: 1.0
            }]
          }));
          break;
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      setError('Failed to upload file');
    }
  };

  const prepareApiInput = () => {
    const input: any = {
      prompt: settings.prompt,
      num_inference_steps: settings.numInferenceSteps,
      guidance_scale: settings.guidanceScale,
      num_images: settings.numImages,
      enable_safety_checker: settings.enableSafetyChecker
    };

    if (typeof settings.imageSize === 'string') {
      input.image_size = settings.imageSize;
    } else {
      input.image_size = settings.imageSize;
    }

    if (settings.seed !== undefined) {
      input.seed = settings.seed;
    }

    if (settings.useRealCfg) {
      input.real_cfg_scale = settings.realCfgScale;
      input.use_real_cfg = true;
    }

    if (settings.mode !== 'text2img') {
      if (settings.referenceImageUrl) {
        input.image_url = settings.referenceImageUrl;
        input.strength = settings.strength || 0.85;
      }
      if (settings.mode === 'inpainting' && settings.maskImageUrl) {
        input.mask_image_url = settings.maskImageUrl;
      }
    }

    if (settings.loras.length > 0) {
      input.loras = settings.loras;
    }

    if (settings.controlnets.length > 0) {
      input.controlnets = settings.controlnets;
    }

    if (settings.ipAdapters.length > 0) {
      input.ip_adapters = settings.ipAdapters;
    }

    return input;
  };

  const handleAgentSelection = (agentName: string) => {
    setSelectedAgents(prev => 
      prev.includes(agentName) 
        ? prev.filter(a => a !== agentName)
        : [...prev, agentName]
    );
  };

  const saveSettings = (name: string) => {
    if (!name.trim()) {
      setError('Please enter a name for the settings');
      return;
    }
    const newSavedSettings = {
      ...savedSettings,
      [name]: settings
    };
    setSavedSettings(newSavedSettings);
    localStorage.setItem('imageGeneratorSettings', JSON.stringify(newSavedSettings));
    setSettingsName('');
    setError(null);
  };

  const loadSettings = (name: string) => {
    if (savedSettings[name]) {
      setSettings(savedSettings[name]);
      setError(null);
    }
  };

  const handleGenerateClick = () => {
    handleGenerate();
  };

  return (
    <div className="image-generator">
      <div className="api-connection">
        <form onSubmit={handleApiKeySubmit}>
          <div className="input-group">
            <label htmlFor="apiKey">FAL.ai API Key:</label>
            <input
              type="password"
              id="falApiKey" // Zmienione ID
              value={apiKey}
              onChange={(e) => onApiKeyChange(e.target.value)}
              placeholder="Enter your FAL.ai API key"
            />
            <button 
              type="submit"
              className={`connect-button ${isApiConnected ? 'connected' : ''}`}
              disabled={isApiConnected}
            >
              {isApiConnected ? 'Connected' : 'Connect to API'}
            </button>
          </div>
        </form>
      </div>

      <div className="settings-management">
        <input
          type="text"
          placeholder="Settings name"
          value={settingsName}
          onChange={(e) => setSettingsName(e.target.value)}
        />
        <button onClick={() => saveSettings(settingsName)}>Save Settings</button>
        <select onChange={(e) => loadSettings(e.target.value)}>
          <option value="">Load saved settings...</option>
          {Object.keys(savedSettings).map(name => (
            <option key={name} value={name}>{name}</option>
          ))}
        </select>
      </div>

      <div className="generation-mode">
        <label>Generation Mode:</label>
        <select
          value={settings.mode}
          onChange={(e) => setSettings(prev => ({ ...prev, mode: e.target.value as GenerationSettings['mode'] }))}
        >
          <option value="text2img">Text to Image</option>
          <option value="img2img">Image to Image</option>
          <option value="inpainting">Inpainting</option>
        </select>
      </div>

      <div className="input-group">
        <label>Prompt:</label>
        <textarea
          value={settings.prompt}
          onChange={(e) => setSettings(prev => ({ ...prev, prompt: e.target.value }))}
          placeholder="Enter your image description..."
        />
      </div>

      <div className="input-group">
        <label>Negative Prompt:</label>
        <textarea
          value={settings.negativePrompt || ''}
          onChange={(e) => setSettings(prev => ({ ...prev, negativePrompt: e.target.value }))}
          placeholder="Enter negative prompt..."
        />
      </div>

      <div className="input-group">
        <label>Image Size:</label>
        <select
          value={typeof settings.imageSize === 'string' ? settings.imageSize : 'custom'}
          onChange={(e) => {
            const value = e.target.value;
            if (value === 'custom') {
              setSettings(prev => ({ ...prev, imageSize: customSize }));
            } else {
              setSettings(prev => ({ ...prev, imageSize: value }));
            }
          }}
        >
          <option value="square_hd">Square HD (1024x1024)</option>
          <option value="square">Square (512x512)</option>
          <option value="portrait_4_3">Portrait 4:3</option>
          <option value="portrait_16_9">Portrait 16:9</option>
          <option value="landscape_4_3">Landscape 4:3</option>
          <option value="landscape_16_9">Landscape 16:9</option>
          <option value="custom">Custom Size</option>
        </select>

        {typeof settings.imageSize !== 'string' && (
          <div className="custom-size">
            <input
              type="number"
              value={customSize.width}
              onChange={(e) => {
                const width = parseInt(e.target.value);
                setCustomSize(prev => ({ ...prev, width }));
                setSettings(prev => ({ ...prev, imageSize: { ...customSize, width } }));
              }}
              placeholder="Width"
              min="64"
              max="2048"
            />
            <input
              type="number"
              value={customSize.height}
              onChange={(e) => {
                const height = parseInt(e.target.value);
                setCustomSize(prev => ({ ...prev, height }));
                setSettings(prev => ({ ...prev, imageSize: { ...customSize, height } }));
              }}
              placeholder="Height"
              min="64"
              max="2048"
            />
          </div>
        )}
      </div>

      {settings.mode !== 'text2img' && (
        <div className="input-group">
          <label>Main Image for {settings.mode}:</label>
          <input
            type="file"
            accept="image/*"
            onChange={(e) => {
              const file = e.target.files?.[0];
              if (file) handleFileUpload(file, 'main');
            }}
          />
          {settings.imageUrl && (
            <div className="preview">
              <img src={settings.imageUrl} alt="Main" className="preview-image" />
            </div>
          )}
          {settings.mode === 'img2img' && (
            <div className="strength-slider">
              <label>Transformation Strength:</label>
              <input
                type="range"
                min="0"
                max="1"
                step="0.05"
                value={settings.strength || 0.85}
                onChange={(e) => setSettings(prev => ({
                  ...prev,
                  strength: parseFloat(e.target.value)
                }))}
              />
              <span>{settings.strength || 0.85}</span>
            </div>
          )}
        </div>
      )}

      {/* Dodajemy kontrolkę dla maski w trybie inpainting */}
      {settings.mode === 'inpainting' && (
        <div className="input-group">
          <label>Mask Image for Inpainting:</label>
          <input
            type="file"
            accept="image/*"
            onChange={(e) => {
              const file = e.target.files?.[0];
              if (file) handleFileUpload(file, 'mask');
            }}
          />
          {settings.maskImageUrl && (
            <div className="preview">
              <img src={settings.maskImageUrl} alt="Mask" className="preview-image" />
            </div>
          )}
          <div className="strength-slider">
            <label>Inpainting Strength:</label>
            <input
              type="range"
              min="0"
              max="1"
              step="0.05"
              value={settings.strength || 1.0}
              onChange={(e) => setSettings(prev => ({
                ...prev,
                strength: parseFloat(e.target.value)
              }))}
            />
            <span>{settings.strength || 1.0}</span>
          </div>
        </div>
      )}

      {/* Sekcja dla reference image pozostaje bez zmian */}
      <div className="input-group">
        <label>Reference Image (Optional):</label>
        <input
          type="file"
          accept="image/*"
          onChange={(e) => {
            const file = e.target.files?.[0];
            if (file) handleFileUpload(file, 'reference');
          }}
        />
        {settings.referenceImageUrl && (
          <div className="preview">
            <img src={settings.referenceImageUrl} alt="Reference" className="preview-image" />
          </div>
        )}
        <div className="reference-controls">
          <div className="strength-slider">
            <label>Reference Strength:</label>
            <input
              type="range"
              min="0"
              max="1"
              step="0.05"
              value={settings.referenceStrength || 0.65}
              onChange={(e) => setSettings(prev => ({
                ...prev,
                referenceStrength: parseFloat(e.target.value)
              }))}
            />
            <span>{settings.referenceStrength || 0.65}</span>
          </div>
          <div className="reference-range">
            <label>Reference Range:</label>
            <input
              type="number"
              min="0"
              max="1"
              step="0.1"
              value={settings.referenceStart || 0}
              onChange={(e) => setSettings(prev => ({
                ...prev,
                referenceStart: parseFloat(e.target.value)
              }))}
              placeholder="Start"
            />
            <input
              type="number"
              min="0"
              max="1"
              step="0.1"
              value={settings.referenceEnd || 1}
              onChange={(e) => setSettings(prev => ({
                ...prev,
                referenceEnd: parseFloat(e.target.value)
              }))}
              placeholder="End"
            />
          </div>
        </div>
      </div>

      {settings.mode === 'inpainting' && (
        <div className="input-group">
          <label>Mask Image:</label>
          <input
            type="file"
            accept="image/*"
            onChange={(e) => {
              const file = e.target.files?.[0];
              if (file) handleFileUpload(file, 'mask');
            }}
          />
          {settings.maskImageUrl && (
            <img src={settings.maskImageUrl} alt="Mask" className="preview-image" />
          )}
        </div>
      )}

      <div className="lora-controls">
        <h3>LoRA Models</h3>
        {settings.loras.map((lora, index) => (
          <div key={index} className="lora-item">
            <input
              type="text"
              value={lora.path}
              onChange={(e) => {
                const newLoras = [...settings.loras];
                newLoras[index].path = e.target.value;
                setSettings(prev => ({ ...prev, loras: newLoras }));
              }}
              placeholder="LoRA path"
            />
            <input
              type="number"
              value={typeof lora.scale === 'number' ? lora.scale : 1}
              onChange={(e) => {
                const newLoras = [...settings.loras];
                newLoras[index].scale = parseFloat(e.target.value);
                setSettings(prev => ({ ...prev, loras: newLoras }));
              }}
              step="0.1"
              placeholder="Scale"
            />
            <button onClick={() => {
              const newLoras = settings.loras.filter((_, i) => i !== index);
              setSettings(prev => ({ ...prev, loras: newLoras }));
            }}>Remove</button>
          </div>
        ))}
        <button onClick={() => {
          setSettings(prev => ({
            ...prev,
            loras: [...prev.loras, { path: '', scale: 1 }]
          }));
        }}>Add LoRA</button>
      </div>

      <div className="controlnet-controls">
        <h3>ControlNet</h3>
        {settings.controlnets.map((controlnet, index) => (
          <div key={index} className="controlnet-item">
            <input
              type="text"
              value={controlnet.path}
              onChange={(e) => {
                const newControlnets = [...settings.controlnets];
                newControlnets[index].path = e.target.value;
                setSettings(prev => ({ ...prev, controlnets: newControlnets }));
              }}
              placeholder="ControlNet path"
            />
            <input
              type="file"
              accept="image/*"
              onChange={(e) => {
                const file = e.target.files?.[0];
                if (file) handleFileUpload(file, 'control');
              }}
            />
            {controlnet.controlImageUrl && (
              <img src={controlnet.controlImageUrl} alt="Control" className="preview-image" />
            )}
            <input
              type="number"
              value={controlnet.conditioningScale}
              onChange={(e) => {
                const newControlnets = [...settings.controlnets];
                newControlnets[index].conditioningScale = parseFloat(e.target.value);
                setSettings(prev => ({ ...prev, controlnets: newControlnets }));
              }}
              step="0.1"
              placeholder="Conditioning Scale"
            />
            <button onClick={() => {
              const newControlnets = settings.controlnets.filter((_, i) => i !== index);
              setSettings(prev => ({ ...prev, controlnets: newControlnets }));
            }}>Remove</button>
          </div>
        ))}
        <button onClick={() => {
          setSettings(prev => ({
            ...prev,
            controlnets: [...prev.controlnets, {
              path: '',
              controlImageUrl: '',
              maskThreshold: 0.5,
              conditioningScale: 1.0,
              startPercentage: 0,
              endPercentage: 1
            }]
          }));
        }}>Add ControlNet</button>
      </div>

      <div className="ip-adapter-controls">
        <h3>IP-Adapter</h3>
        {settings.ipAdapters.map((ipAdapter, index) => (
          <div key={index} className="ip-adapter-item">
            <input
              type="text"
              value={ipAdapter.path}
              onChange={(e) => {
                const newIpAdapters = [...settings.ipAdapters];
                newIpAdapters[index].path = e.target.value;
                setSettings(prev => ({ ...prev, ipAdapters: newIpAdapters }));
              }}
              placeholder="IP-Adapter path"
            />
            <input
              type="file"
              accept="image/*"
              onChange={(e) => {
                const file = e.target.files?.[0];
                if (file) handleFileUpload(file, 'ipAdapter');
              }}
            />
            {ipAdapter.imageUrl && (
              <img src={ipAdapter.imageUrl} alt="IP-Adapter" className="preview-image" />
            )}
            <input
              type="number"
              value={ipAdapter.scale}
              onChange={(e) => {
                const newIpAdapters = [...settings.ipAdapters];
                newIpAdapters[index].scale = parseFloat(e.target.value);
                setSettings(prev => ({ ...prev, ipAdapters: newIpAdapters }));
              }}
              step="0.1"
              placeholder="Scale"
            />
            <button onClick={() => {
              const newIpAdapters = settings.ipAdapters.filter((_, i) => i !== index);
              setSettings(prev => ({ ...prev, ipAdapters: newIpAdapters }));
            }}>Remove</button>
          </div>
        ))}
        <button onClick={() => {
          setSettings(prev => ({
            ...prev,
            ipAdapters: [...prev.ipAdapters, {
              path: '',
              imageUrl: '',
              maskThreshold: 0.5,
              scale: 1.0
            }]
          }));
        }}>Add IP-Adapter</button>
      </div>

      <div className="generation-controls">
        <div className="input-group">
          <label>Steps:</label>
          <input
            type="number"
            value={settings.numInferenceSteps}
            onChange={(e) => setSettings(prev => ({ ...prev, numInferenceSteps: parseInt(e.target.value) }))}
            min="1"
            max="100"
          />
        </div>

        <div className="input-group">
          <label>Guidance Scale:</label>
          <input
            type="number"
            value={settings.guidanceScale}
            onChange={(e) => setSettings(prev => ({ ...prev, guidanceScale: parseFloat(e.target.value) }))}
            step="0.1"
            min="1"
            max="20"
          />
        </div>

        <div className="input-group">
          <label>
            <input
              type="checkbox"
              checked={settings.useRealCfg}
              onChange={(e) => setSettings(prev => ({ ...prev, useRealCfg: e.target.checked }))}
            />
            Use Real CFG
          </label>
        </div>

        {settings.useRealCfg && (
          <div className="input-group">
            <label>Real CFG Scale:</label>
            <input
              type="number"
              value={settings.realCfgScale}
              onChange={(e) => setSettings(prev => ({ ...prev, realCfgScale: parseFloat(e.target.value) }))}
              step="0.1"
              min="1"
              max="20"
            />
          </div>
        )}

        <div className="input-group">
          <label>Number of Images:</label>
          <input
            type="number"
            value={settings.numImages}
            onChange={(e) => setSettings(prev => ({ ...prev, numImages: parseInt(e.target.value) }))}
            min="1"
            max="4"
          />
        </div>

        <div className="input-group">
          <label>
            <input
              type="checkbox"
              checked={settings.enableSafetyChecker}
              onChange={(e) => setSettings(prev => ({ ...prev, enableSafetyChecker: e.target.checked }))}
            />
            Enable Safety Checker
          </label>
        </div>

        <div className="input-group">
          <label>Seed (optional):</label>
          <input
            type="number"
            value={settings.seed || ''}
            onChange={(e) => setSettings(prev => ({ ...prev, seed: e.target.value ? parseInt(e.target.value) : undefined }))}
          />
        </div>
      </div>

      <div className="agent-selection">
        <h3>Generate from Agents</h3>
        <div className="agent-list">
          {agents.map(agent => (
            <label key={agent} className="agent-checkbox">
              <input
                type="checkbox"
                checked={selectedAgents.includes(agent)}
                onChange={() => handleAgentSelection(agent)}
              />
              <span>{agent}</span>
            </label>
          ))}
        </div>
        <button
          onClick={() => queuePrompts()}
          disabled={selectedAgents.length === 0 || isLoading || isProcessing}
          className="generate-from-agents-button"
        >
          {isLoading ? 'Fetching prompts...' : isProcessing ? 'Generating...' : 'Generate from Selected Agents'}
        </button>
      </div>

      <button 
        className="generate-button"
        onClick={handleGenerateClick}
        disabled={!isApiConnected || isLoading || isProcessing}
      >
        {isLoading || isProcessing ? 'Generating...' : 'Generate Image'}
      </button>

      {error && (
        <div className="error-message">
          {error}
        </div>
      )}

      <div className="generated-images">
        {generatedImages.map((image, index) => (
          <div key={index} className="image-container">
            <img src={image.url} alt={`Generated ${index + 1}`} />
            <a href={image.url} download={`generated-${index + 1}.jpg`} className="download-button">
              Download
            </a>
          </div>
        ))}
      </div>
    </div>
  );
});

export default ImageGenerator;