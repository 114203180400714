import React, { useState } from 'react';

interface AgentGraphProps {
  agents: string[];
  connections: { [key: string]: string[] };
  onConnectionChange: (connections: { [key: string]: string[] }) => void;
  onAddAgent: (agentName: string) => void;
  onSelectAgent: (agent: string) => void;
}

const AgentGraph: React.FC<AgentGraphProps> = ({
  agents,
  connections,
  onConnectionChange,
  onAddAgent,
  onSelectAgent
}) => {
  const [newAgentName, setNewAgentName] = useState('');
  const [selectedAgent, setSelectedAgent] = useState<string | null>(null);

  const handleAddAgent = () => {
    if (newAgentName.trim() && !agents.includes(newAgentName.trim())) {
      onAddAgent(newAgentName.trim());
      setNewAgentName('');
    }
  };

  const handleAgentClick = (agent: string) => {
    setSelectedAgent(agent);
    onSelectAgent(agent);
  };

  const handleConnectionToggle = (source: string, target: string) => {
    const newConnections = { ...connections };
    if (newConnections[source]?.includes(target)) {
      newConnections[source] = newConnections[source].filter(a => a !== target);
    } else {
      if (!newConnections[source]) {
        newConnections[source] = [];
      }
      newConnections[source].push(target);
    }
    onConnectionChange(newConnections);
  };

  return (
    <div className="agent-graph">
      <div className="agent-list">
        {agents.map(agent => (
          <div
            key={agent}
            className={`agent-item ${selectedAgent === agent ? 'selected' : ''}`}
            onClick={() => handleAgentClick(agent)}
          >
            {agent}
          </div>
        ))}
      </div>
      <div className="connection-matrix">
        <table>
          <thead>
            <tr>
              <th></th>
              {agents.map(agent => <th key={agent}>{agent}</th>)}
            </tr>
          </thead>
          <tbody>
            {agents.map(source => (
              <tr key={source}>
                <td>{source}</td>
                {agents.map(target => (
                  <td key={`${source}-${target}`}>
                    {source !== target && (
                      <input
                        type="checkbox"
                        checked={connections[source]?.includes(target) || false}
                        onChange={() => handleConnectionToggle(source, target)}
                      />
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="add-agent-form">
        <input
          type="text"
          value={newAgentName}
          onChange={(e) => setNewAgentName(e.target.value)}
          placeholder="New agent name"
        />
        <button onClick={handleAddAgent}>Add Agent</button>
      </div>
    </div>
  );
};

export default AgentGraph;