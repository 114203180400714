import { fal } from "@fal-ai/client";
import { Project, ProjectMetadata } from '../types/project';


const getBaseUrl = () => {
  // Jeśli aplikacja działa na porcie 3000 (development), używaj pełnego URL
  if (window.location.port === '3000') {
    return 'http://localhost:5000/api';
  }
  // W przeciwnym razie używaj ścieżki względnej (dla NGINX)
  return '/api';
};

const API_BASE_URL = getBaseUrl();

export interface SearchResult {
  title: string;
  description?: string;
  url?: string;
  snippet?: string;
}

export interface KeywordQuestion {
  question: string;
  searchVolume: number;
  monthlyTrend?: Array<{
    year: number;
    month: number;
    volume: number;
  }>;
  competitionLevel?: string;
}

export interface AgentSumResponse {
  tofuQuestions: string[];
  mofuQuestions: string[];
}

export interface SearchResponse {
  searchResults: SearchResult[];
  relatedQuestions: {
    whatQuestions: KeywordQuestion[];
    howQuestions: KeywordQuestion[];
  };
  agentSumQuestions?: AgentSumResponse;
}

export interface AgentPrompt {
  agentName: string;
  prompt: string;
}

export interface GeneratedImage {
  url: string;
  contentType: string;
}

export interface ImageGenerationOptions {
  prompt: string;
  imageSize?: string | { width: number; height: number };
  numInferenceSteps?: number;
  guidanceScale?: number;
  numImages?: number;
  seed?: number;
  // Dodaj inne opcje zgodnie z potrzebami
}

export const sendMessage = async (content: string, agent: string, selectedHistories: string[] = []) => {
  try {
    const response = await fetch(`${API_BASE_URL}/chat`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ content, agent, selectedHistories }),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    return await response.json();
  } catch (error) {
    console.error('Error sending message:', error);
    throw error;
  }
};

export const updateHistory = async (agent: string, history: string) => {
  try {
    const response = await fetch(`${API_BASE_URL}/update-history`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ agent, history }),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    return await response.json();
  } catch (error) {
    console.error('Error updating history:', error);
    throw error;
  }
};

export const getAgents = async (): Promise<string[]> => {
  try {
    const response = await fetch(`${API_BASE_URL}/agents`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error getting agents:', error);
    throw error;
  }
};

export const getCustomInstructions = async (agent: string): Promise<string> => {
  try {
    const response = await fetch(`${API_BASE_URL}/custom-instructions/${agent}`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data.instructions;
  } catch (error) {
    console.error('Error getting custom instructions:', error);
    throw error;
  }
};

export const updateCustomInstructions = async (agent: string, instructions: string): Promise<void> => {
  try {
    const response = await fetch(`${API_BASE_URL}/update-custom-instructions`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ agent, instructions }),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    await response.json();
  } catch (error) {
    console.error('Error updating custom instructions:', error);
    throw error;
  }
};

export const getMessageContext = async (agent: string, contextId: string): Promise<string> => {
  try {
    const response = await fetch(`${API_BASE_URL}/message-context/${agent}/${contextId}`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data.content;
  } catch (error) {
    console.error('Error getting message context:', error);
    throw error;
  }
};

export const updateMessageContext = async (
  agent: string, 
  contextId: string, 
  content: string
): Promise<void> => {
  try {
    const response = await fetch(`${API_BASE_URL}/update-message-context`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ agent, contextId, content }),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    await response.json();
  } catch (error) {
    console.error('Error updating message context:', error);
    throw error;
  }
};

export const resetAgentHistory = async (agent: string): Promise<void> => {
  try {
    const response = await fetch(`${API_BASE_URL}/reset-history`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ agent }),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    await response.json();
  } catch (error) {
    console.error('Error resetting agent history:', error);
    throw error;
  }
};

export const updateConnections = async (connections: { [key: string]: string[] }): Promise<void> => {
  try {
    const response = await fetch(`${API_BASE_URL}/update-connections`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ connections }),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    await response.json();
  } catch (error) {
    console.error('Error updating connections:', error);
    throw error;
  }
};

export const getConnections = async (): Promise<{ [key: string]: string[] }> => {
  try {
    const response = await fetch(`${API_BASE_URL}/connections`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error getting connections:', error);
    throw error;
  }
};

export const addAgent = async (agentName: string): Promise<void> => {
  try {
    const response = await fetch(`${API_BASE_URL}/add-agent`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ agent: agentName }),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    await response.json();
  } catch (error) {
    console.error('Error adding new agent:', error);
    throw error;
  }
};

export const getHistory = async (agent: string): Promise<string> => {
  try {
    const response = await fetch(`${API_BASE_URL}/history/${agent}`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.text();
  } catch (error) {
    console.error('Error getting history:', error);
    throw error;
  }
};

export const getAgentHistoryConfig = async (agent: string): Promise<string[]> => {
  try {
    const response = await fetch(`${API_BASE_URL}/agent-history-config/${agent}`);
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error('Error getting agent history config:', error);
    return []; // Zwróć pustą tablicę w przypadku błędu
  }
};

export const updateAgentHistoryConfig = async (agent: string, selectedHistories: string[]): Promise<void> => {
  try {
    const response = await fetch(`${API_BASE_URL}/update-agent-history-config`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ agent, selectedHistories }),
    });
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
    }
  } catch (error) {
    console.error('Error updating agent history config:', error);
    throw error;
  }
};

export const deleteAgent = async (agent: string): Promise<void> => {
  try {
    const response = await fetch(`${API_BASE_URL}/agent/${agent}`, {
      method: 'DELETE',
    });

    if (!response.ok) {
      throw new Error('Failed to delete agent');
    }
  } catch (error) {
    console.error('Error deleting agent:', error);
    throw error;
  }
};

export const renameAgent = async (agent: string, newName: string): Promise<void> => {
  try {
    const response = await fetch(`${API_BASE_URL}/agent/${agent}/rename`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ newName }),
    });

    if (!response.ok) {
      const data = await response.json();
      throw new Error(data.error || 'Failed to rename agent');
    }
  } catch (error) {
    console.error('Error renaming agent:', error);
    throw error;
  }
};

export const processDataWithAgentSum = async (dataforseoData: any): Promise<AgentSumResponse> => {
  try {
    console.log('Sending data to AgentSum:', dataforseoData);
    const response = await fetch(`${API_BASE_URL}/process-with-agent-sum`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ dataforseoData }),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    console.log('Received AgentSum response:', data);

    if (!data.tofuQuestions || !data.mofuQuestions) {
      throw new Error('Invalid AgentSum response format');
    }

    return {
      tofuQuestions: data.tofuQuestions,
      mofuQuestions: data.mofuQuestions
    };
  } catch (error) {
    console.error('Error processing data with AgentSum:', error);
    throw error;
  }
};

export const searchQuestions = async (keyword: string): Promise<SearchResponse> => {
  try {
    console.log('Searching questions for keyword:', keyword);
    
    const dataforseoResponse = await fetch(`${API_BASE_URL}/search-questions`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ keyword }),
    });

    if (!dataforseoResponse.ok) {
      throw new Error('DataForSEO request failed');
    }

    const dataforseoData = await dataforseoResponse.json();
    console.log('Received DataForSEO response:', dataforseoData);

    if (dataforseoData.error) {
      throw new Error(dataforseoData.error);
    }

    const agentSumResponse = await processDataWithAgentSum(dataforseoData);
    console.log('Processed by AgentSum:', agentSumResponse);

    const response: SearchResponse = {
      searchResults: dataforseoData.searchResults || [],
      relatedQuestions: {
        whatQuestions: dataforseoData.relatedQuestions.whatQuestions || [],
        howQuestions: dataforseoData.relatedQuestions.howQuestions || []
      },
      agentSumQuestions: agentSumResponse
    };

    console.log('Final search response:', response);
    return response;

  } catch (error) {
    console.error('Error in searchQuestions:', error);
    throw error;
  }
};

export const getAgentPrompts = async (agents: string[]): Promise<AgentPrompt[]> => {
  try {
    console.log('Wysyłanie zapytania o prompty dla agentów:', agents);
    
    const response = await fetch(`${API_BASE_URL}/get-agent-prompts`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ agents }),
    });

    if (!response.ok) {
      throw new Error('Failed to fetch agent prompts');
    }

    const data = await response.json();
    console.log('Otrzymane prompty:', data);
    return data;
  } catch (error) {
    console.error('Error fetching agent prompts:', error);
    throw error;
  }
};

export const checkHistoryStatus = async (agent: string): Promise<boolean> => {
  try {
    const response = await fetch(`${API_BASE_URL}/check-history-status/${agent}`);
    if (!response.ok) {
      throw new Error('Failed to check history status');
    }
    const data = await response.json();
    return data.isReady;
  } catch (error) {
    console.error('Error checking history status:', error);
    return false;
  }
};

export const deleteImage = async (type: string, filename: string): Promise<void> => {
  try {
    const response = await fetch(`${API_BASE_URL}/images/${type}/${filename}`, {
      method: 'DELETE',
    });

    if (!response.ok) {
      throw new Error('Failed to delete image');
    }
  } catch (error) {
    console.error('Error deleting image:', error);
    throw error;
  }
};

export const saveGeneratedImages = async (images: GeneratedImage[]): Promise<void> => {
  try {
    const response = await fetch(`${API_BASE_URL}/save-images`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ images }),
    });

    if (!response.ok) {
      throw new Error('Failed to save images');
    }
  } catch (error) {
    console.error('Error saving images:', error);
    throw error;
  }
};

export const generateImage = async (options: ImageGenerationOptions): Promise<GeneratedImage> => {
  try {
    const result = await fal.subscribe("fal-ai/flux-general", {
      input: {
        prompt: options.prompt,
        image_size: options.imageSize || "square_hd",
        num_inference_steps: options.numInferenceSteps || 28,
        guidance_scale: options.guidanceScale || 3.5,
        num_images: options.numImages || 1,
        enable_safety_checker: true,
        sync_mode: true,
        seed: options.seed
      },
    });

    if (result.data && result.data.images && result.data.images.length > 0) {
      return {
        url: result.data.images[0].url,
        contentType: result.data.images[0].content_type || 'image/jpeg'
      };
    } else {
      throw new Error('No image generated');
    }
  } catch (error) {
    console.error('Error generating image:', error);
    throw error;
  }
};

export const projectApi = {
  async listProjects(): Promise<ProjectMetadata[]> {
    const response = await fetch(`${API_BASE_URL}/projects`);
    if (!response.ok) {
      throw new Error('Failed to list projects');
    }
    return response.json();
  },

  async getProject(id: string): Promise<Project> {
    const response = await fetch(`${API_BASE_URL}/projects/${id}`);
    if (!response.ok) {
      throw new Error('Failed to get project');
    }
    return response.json();
  },

  async createProject(name: string, description?: string): Promise<Project> {
    const response = await fetch(`${API_BASE_URL}/projects`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name, description }),
    });
    if (!response.ok) {
      throw new Error('Failed to create project');
    }
    return response.json();
  },

  async saveCurrentState(name: string, description?: string): Promise<Project> {
    const response = await fetch(`${API_BASE_URL}/projects/save-current`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name, description }),
    });

    if (!response.ok) {
      throw new Error('Failed to save current state');
    }

    return response.json();
  },

  async saveProject(project: Project): Promise<void> {
    const response = await fetch(`${API_BASE_URL}/projects/${project.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(project),
    });
    if (!response.ok) {
      throw new Error('Failed to save project');
    }
  },

  async deleteProject(id: string): Promise<void> {
    const response = await fetch(`${API_BASE_URL}/projects/${id}`, {
      method: 'DELETE',
    });
    if (!response.ok) {
      throw new Error('Failed to delete project');
    }
  }
};