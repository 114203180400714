import React, { useState, useEffect } from 'react';
import { Project, ProjectMetadata } from '../types/project';
import { projectApi } from '../services/api';
import '../styles/ProjectManager.css';

interface ProjectManagerProps {
  onProjectLoad: (project: Project) => void;
  onClose: () => void;
}

const ProjectManager: React.FC<ProjectManagerProps> = ({ onProjectLoad, onClose }) => {
  const [projects, setProjects] = useState<ProjectMetadata[]>([]);
  const [newProjectName, setNewProjectName] = useState('');
  const [newProjectDescription, setNewProjectDescription] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    loadProjects();
  }, []);

  const loadProjects = async () => {
    try {
      setIsLoading(true);
      const projectsList = await projectApi.listProjects();
      setProjects(projectsList);
    } catch (error) {
      setError('Failed to load projects');
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateProject = async () => {
    if (!newProjectName.trim()) {
      setError('Project name is required');
      return;
    }

    try {
      setIsLoading(true);
      const project = await projectApi.createProject(newProjectName, newProjectDescription);
      setProjects([...projects, project]);
      setNewProjectName('');
      setNewProjectDescription('');
    } catch (error) {
      setError('Failed to create project');
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLoadProject = async (projectId: string) => {
    try {
      setIsLoading(true);
      const project = await projectApi.getProject(projectId);
      onProjectLoad(project);
    } catch (error) {
      setError('Failed to load project');
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteProject = async (projectId: string) => {
    if (!window.confirm('Are you sure you want to delete this project?')) {
      return;
    }

    try {
      setIsLoading(true);
      await projectApi.deleteProject(projectId);
      setProjects(projects.filter(p => p.id !== projectId));
    } catch (error) {
      setError('Failed to delete project');
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveCurrentState = async () => {
    if (!newProjectName.trim()) {
      setError('Project name is required');
      return;
    }

    try {
      setIsSaving(true);
      setError(null);
      const project = await projectApi.saveCurrentState(newProjectName, newProjectDescription);
      setProjects([...projects, project]);
      setNewProjectName('');
      setNewProjectDescription('');
    } catch (error) {
      setError('Failed to save current state');
      console.error(error);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="project-manager">
      <div className="project-manager-header">
        <h2>Project Manager</h2>
        <button onClick={onClose}>Close</button>
      </div>

      <div className="create-project">
        <h3>Create New Project</h3>
        <input
          type="text"
          value={newProjectName}
          onChange={(e) => setNewProjectName(e.target.value)}
          placeholder="Project Name"
        />
        <textarea
          value={newProjectDescription}
          onChange={(e) => setNewProjectDescription(e.target.value)}
          placeholder="Project Description (optional)"
        />
        <button 
          onClick={handleCreateProject}
          disabled={isLoading || !newProjectName.trim()}
        >
          Create Project
        </button>
        <button 
          onClick={handleCreateProject}
          disabled={isLoading || !newProjectName.trim()}
        >
          Create Empty Project
        </button>
        <button 
          onClick={handleSaveCurrentState}
          disabled={isSaving || !newProjectName.trim()}
        >
          Save Current State
        </button>
      </div>

      <div className="projects-list">
        <h3>Your Projects</h3>
        {isLoading && <div className="loading">Loading...</div>}
        {error && <div className="error">{error}</div>}
        {projects.map(project => (
          <div key={project.id} className="project-item">
            <div className="project-info">
              <h4>{project.name}</h4>
              {project.description && <p>{project.description}</p>}
              <span className="project-date">
                Last modified: {new Date(project.lastModified).toLocaleDateString()}
              </span>
            </div>
            <div className="project-actions">
              <button onClick={() => handleLoadProject(project.id)}>Load</button>
              <button 
                onClick={() => handleDeleteProject(project.id)}
                className="delete-button"
              >
                Delete
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProjectManager;